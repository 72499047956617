<template>
  <div>
    <Menu></Menu>
    <div class="main-box ">
      <div class="earth-row">
        <div>
          <h1>方案案例</h1>
          <p>科缔云服务能使您的设备迅速升级为云端设备，为消费者带来炫酷体验，让世界触手可及。</p>
          <div style="height: 48px; "></div>
        </div>
        <div class="earth-row-img">
          <img src="/img/img/index.png">
        </div>
      </div>
    </div>
    <div class="introduce" id="synopsis">
      <div class="int-title-box">
        <h2 class="int-title">方案简介</h2>
        <!-- <p class="int-sub-title">
          深耕行业多年，我们已发展为全球领先的 IoT 音视频平台。专业为您提供完善的产品服务
        </p> -->
      </div>
      <div class="int-flex-box">
        <div class="int-flex-box-item no-move no-border full-box">
          <div>
            <div class="int-flex-box-half img">
              <img src="/img/img/233796532506447872.png">
            </div>
            <div class="int-flex-box-half">
              <h3 class="int-flex-box-item-title">产品引用解决方案</h3>
              <div class="int-flex-box-item-info">
                <p>
                  科缔云端服务平台专为产品制造商提供快速而简单的云端整合方案，让您的产品轻松拥有云端服务的增值功能。我们已与全球几十家芯片厂策略合作，科缔云支持各类SoC系统单芯片，让各种连网终端产品于短时间内就可在消费市场产生惊人的扩散效果，我们也可为您的产品推荐合适的配搭产品与合作伙伴。此外，我们也提供定制化app帮助您的产品与手持行动装置轻松连接进行远程操作。
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="int-flex-box-item no-move no-border full-box">
          <div>
            <div class="int-flex-box-half">
              <h3 class="int-flex-box-item-title">消费类应用产品 </h3>
              <div class="int-flex-box-item-info">
                <p>
                  科缔云所支持的智慧家庭产品，有连接方便及操作简单的特性，可快速深入消费市场让消费者轻松操作家中各种连网产品，同时又可享受云端平台提供的服务；科缔云可将家中各式连网家电及产品的操作接口统合为一，由app即可同时控制不同功能的产品。云端服务平台可提供适用于各种操作系统的应用程序编程接口(API)，缩短产品开发时间，并凭借产品连接设定简单的特性，协助企业降低产品的售后服务成本。
                </p>
              </div>
            </div>
            <div class="int-flex-box-half img">
              <img src="/img/img/233796532506447872.png">
            </div>
          </div>
        </div>
        <div class="int-flex-box-item no-move no-border full-box">
          <div>
            <div class="int-flex-box-half img">
              <img src="/img/img/233796532506447872.png">
            </div>
            <div class="int-flex-box-half">
              <h3 class="int-flex-box-item-title">可应用的设备</h3>
              <div class="int-flex-box-item-info">
                <p>
                  网络摄像机(IPC) 、数字硬盘录像机(DVR)、网络硬盘录像机(NVR)、可视门铃、智能家居系统、功能集成芯片、安全报警器、婴儿监视器、宠物监视器、机顶盒、路由器等等各种联网设备
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="introduce" id="case">
      <div class="int-title-box">
        <h2 class="int-title">成功案例</h2>
        <!-- <p class="int-sub-title">
          深耕行业多年，我们已发展为全球领先的 IoT 音视频平台。专业为您提供完善的产品服务
        </p> -->
      </div>
      <div class="int-flex-box case-box">
        <div class="int-flex-box-item thr-box">
          <div>
            <div class="int-flex-box-img">
              <img src="/img/logo/杭州韬视logo.png">
            </div>
            <div class="int-flex-box-span">
              <h3 class="int-flex-box-item-title">杭州韬视科技有限公司</h3>
              <div class="int-flex-box-item-info">
                <p>
                  合作内容：接入P2P穿透技术，搭建私有云服务器，定制配套客户端，定制部分特殊功能，
                </p>
                <p>
                  目前市场使用情况：全球部署上百台服务器，在线设备超过100万，特别是海外市场用户量巨大
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="int-flex-box-item thr-box">
          <div>
            <div class="int-flex-box-img">
              <img src="/img/logo/158529440661116915.png">
            </div>
            <div class="int-flex-box-span">
              <h3 class="int-flex-box-item-title">苏宁智能终端有限公司</h3>
              <div class="int-flex-box-item-info">
                <p>
                  合作内容：苏宁在自有平台的基础上引入科缔云，我们提供P2P透传技术以及APP SDK接入技术支持，并为其搭建私有云服务器
                </p>
                <p>
                  目前市场使用情况：已在消费类摄像头、智能门铃等多款物联网产品上进行应用
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="int-flex-box-item thr-box">
          <div>
            <div class="int-flex-box-img">
              <img src="/img/logo/海清视讯.jpg">
            </div>
            <div class="int-flex-box-span">
              <h3 class="int-flex-box-item-title">深圳市海清视讯科技有限公司</h3>
              <div class="int-flex-box-item-info">
                <p>
                  合作内容：提供P2P穿透技术，定制开发客户端，租用我司服务器
                </p>
                <p>
                  目前市场使用情况：正在市场试运行，效果反应良好，定制客户端功能不断完善，特别是车牌识别，人脸识别方面技术已经较为成熟
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="int-flex-box-item thr-box">
          <div>
            <div class="int-flex-box-img">
              <img src="/img/logo/wjalogo.png">
            </div>
            <div class="int-flex-box-span">
              <h3 class="int-flex-box-item-title">深圳市万佳安物联科技股份有限公司</h3>
              <div class="int-flex-box-item-info">
                <p>
                  合作内容：提供P2P穿透技术，集成到终端设备，为客户搭建服务器，定制配套客户端，定制部分特殊功能
                </p>
                <p>
                  目前市场使用情况：集成到智能终端产品目前销往欧洲、北美等各发达国家，在国内市场反应良好，正在扩大规模及研发新产品。
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="int-flex-box-item thr-box">
          <div>
            <div class="int-flex-box-img">
              <img src="/img/logo/华意logo.png">
            </div>
            <div class="int-flex-box-span">
              <h3 class="int-flex-box-item-title">深圳市华意视讯科技有限公司</h3>
              <div class="int-flex-box-item-info">
                <p>
                  合作内容：提供P2P穿透技术，集成到设备主芯片中，提供客户端sdk接入，提供技术支持，租用我司服务器
                </p>
                <p>
                  目前市场使用情况：年购买license大超100k,目前正大力推广云存储功能
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="int-flex-box-item thr-box">
          <div>
            <div class="int-flex-box-img">
              <img src="/img/logo/天地宽视_logo.gif">
            </div>
            <div class="int-flex-box-span">
              <h3 class="int-flex-box-item-title">深圳天地宽视信息科技有限公司</h3>
              <div class="int-flex-box-item-info">
                <p>
                  合作内容：提供P2P穿透技术，提供app接入技术支持，为其搭建私有云服务器
                </p>
                <p>
                  目前市场使用情况：主攻高端市场，目前服务器上百台，日上线数过百万，运行稳定，已经得到市场认可。海外业务大量增长，服务器数量也逐步增加。
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Foot></Foot>
  </div>
</template>

<script>
import Menu from "@/pages/Dashboard/Menu.vue";
import Foot from "@/pages/Dashboard/Foot.vue";
export default {
  components:{
    Menu : Menu,
    Foot : Foot,
  },
  data() {
    return {
    };
  },
  mounted() {
  },
  methods : {
  }
};
</script>
<style scoped>
  body, h2, h3, h4, h5, h6, .h2, .h3, .h4, a, p{
    font-weight: 400 !important;
  }
  .int-flex-box-half .int-flex-box-item-title{
    font-size: 36px;
    line-height: 46px;
    margin-bottom: 36px;
    height: unset;
  }
  .int-flex-box-half.img{
    text-align: center;
  }
  img{
    max-width: 100%;
  }
  .int-flex-box-half{
    box-sizing: border-box;
    padding: 0 36px;
    flex-basis: 50%;
    flex-grow: 0;
    flex-shrink: 0;
    max-width: 50%;
  }
  .int-flex-box-img img{
    width: 300px;
  }
  .int-flex-box-item-info p{
    margin: 4px 0;
  }
  .int-flex-box-item-info{
    font-size: 16px;
    font-family: "PingFang SC";
    line-height: 26px;
    color: rgb(96,98,102);
    margin: 0;
  }
  .int-flex-box-item-title{
    font-size: 20px;
    height: 30px;
    margin: 0;
    margin-bottom: 12px;
  }
  .int-flex-box-img{
    max-width: 300px;
    min-width: 300px;
  }
  .int-flex-box-span{
    display: flex;
    flex: 1 1 auto;
    padding: 54px 16px 54px 40px;
    flex-direction: column;
  }
  .int-flex-box-item>div:hover{
    top: -10px;
    box-shadow: 0 -2px 10px 0 hsl(225deg 3% 46% / 3%), 0 14px 20px 0 hsl(225deg 3% 46% / 18%);
  }
  .int-flex-box-item.no-move>div:hover{
    top: 0;
    box-shadow: 0 4px 15px 0 rgb(37 60 126 / 12%);
  }
  .int-flex-box-item.no-border>div{
    border: 0 !important;
    box-shadow: none !important;
  }
  .int-flex-box-item>div{
    display: flex;
    background-color: rgb(255, 255, 255);
    min-height: 240px;
    height: 100%;
    margin: 0px auto;
    box-shadow: 0 4px 15px 0 rgb(37 60 126 / 12%);
    border-radius: 8px;
    position: relative;
    transition: top .3s, box-shadow .3s;
    top: 0;
    justify-content: center;
    align-items: center;
  }
  .int-flex-box-item.full-box{
    max-width: 100%;
    flex-basis: 100%;
  }
  .int-flex-box-item{
    padding: 12px;
    flex-basis: 50%;
    flex-grow: 0;
    flex-shrink: 0;
    max-width: 50%;
    box-sizing: border-box;
  }
  .int-flex-box{
    margin-left: -12px;
    margin-right: -12px;
    margin-top: -12px;
    margin-bottom: 12px;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    align-items: center;
  }

  
  .case-box .int-flex-box-item.thr-box>div{
    display: flex;
    flex-direction: column;
    padding: 48px 32px 0px;
  }
  .case-box .int-flex-box-item.thr-box{
    max-width: 33%;
    flex-basis: 33%;
    margin-bottom: 48px;
  }
  .case-box .int-flex-box-img img{
    width: auto;
    height: 60px;
  }
  .case-box .int-flex-box-item-info p{
    margin: 4px 0;
  }
  .case-box .int-flex-box-item-info{
    font-size: 16px;
    font-family: "PingFang SC";
    line-height: 26px;
    color: rgb(96,98,102);
    margin: 0;
  }
  .case-box .int-flex-box-item-title{
    font-size: 20px;
    height: 30px;
    margin: 0;
    margin-bottom: 12px;
  }
  .case-box .int-flex-box-img{
    max-width: 300px;
    min-width: 300px;
  }
  .case-box .int-flex-box-span{
    display: flex;
    flex: 1 1 auto;
    padding: 16px;
    flex-direction: column;
  }
  .case-box .int-flex-box-item>div:hover{
    top: -10px;
    box-shadow: 0 -2px 10px 0 hsl(225deg 3% 46% / 3%), 0 14px 20px 0 hsl(225deg 3% 46% / 18%);
  }
  .case-box .int-flex-box-item>div{
    display: flex;
    background-color: rgb(255, 255, 255);
    min-height: 240px;
    /*height: 100%;*/
    margin: 0px auto;
    box-shadow: 0 4px 15px 0 rgb(37 60 126 / 12%);
    border-radius: 8px;
    position: relative;
    transition: top .3s, box-shadow .3s;
    top: 0;
  }
  .case-box .int-flex-box-item.full-box{
    max-width: 100%;
    flex-basis: 100%;
  }
  .case-box .int-flex-box-item{
    padding: 12px;
    flex-basis: 50%;
    flex-grow: 0;
    flex-shrink: 0;
    max-width: 50%;
    box-sizing: border-box;
  }
  .case-box.int-flex-box{
    /*padding-bottom: 72px;*/
    margin-left: -12px;
    margin-right: -12px;
    margin-top: -12px;
    margin-bottom: 12px;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    align-items: stretch;
  }
  .int-title-box{
    text-align: center;
    padding: 0 12px 60px;
  }
  .int-sub-title{
    margin-top:24px;
    color: rgb(96, 98, 102);
    font-size: 16px;
    line-height: 26px;
    height: 26px;
    margin-bottom: 0;
  }
  .int-title{
    line-height: 50px;
    height: 50px;
    margin: 0;
    font-size: 40px;
    font-weight: 700;
  }
  div.introduce {
    margin: 72px auto;
    /* margin: 72px 0; */
    padding: 0 72px;
    max-width: 1200px;
  }
  .earth-row{
    max-width: 1300px;
    display: flex;
    margin: 0 auto;
    height: 500px;
  }
  .earth-row img{
    width: 100%;
  }
  .earth-row p{
    color: #606266;
    line-height: 26px;
  }

  .earth-row>div{
    align-items:center;
    padding: 0 12px;
    width: 50%;
    align-self: center;
    box-sizing: border-box;
    /*padding-left: 12px;*/
    /*padding-right: 12px;*/
    flex-basis: 50%;
    flex-grow: 0;
    flex-shrink: 0;
    max-width: 50%;
  }
  .main-box p{
    margin: 32px 0 42px;
    font-size: 16px;
  }
  .main-box h1{
    line-height: 66px;
    font-size: 56px;
    margin: 0;
    font-weight: bold;
    color: #000;
    /*margin-top: 64px;*/
  }
  .main-box{
    box-shadow: inset 0 0 50px rgb(0 0 0 / 5%);
    box-sizing: border-box;
    height: 500px;
    width: 100%;
    /*padding: 0 72px;*/
    margin: 0 auto;
    background-color: #eef2f6;
    clear: both;
    background-repeat: no-repeat;
    background-size: cover;
    flex-wrap: wrap;
    font-family: PingFang-SC-Regular, "PingFang SC", "Helvetica Neue", Helvetica, Arial, "Microsoft Yahei", "Hiragino Sans GB", "Heiti SC", "WenQuanYi Micro Hei", sans-serif;
  }
  @media screen and (max-width: 1023px){
    .int-flex-box.case-box .int-flex-box-item{
      max-width: 100%;
      flex-basis: 100%;
      margin: 24px 0;
    }
    .introduce .int-flex-box>div>div{
      flex-direction: column;
    }
    div.introduce{
      padding: 0 32px;
    }
    .int-flex-box-half{
        box-sizing: border-box;
        flex-basis: 100%;
        max-width: 100%;
        padding: 0;
        flex-grow: 0;
        flex-shrink: 0;
    }
    .init-title-box{
      height: 150px;
    }
    .main-box p {
      margin: 24px 0;
    }
    .main-box {
      padding: 0 12px;
      height: unset;
    }
    .earth-row{
      height: unset;
    }
    .earth-row>div{
      width: 100%;
      max-width: 100%;
      flex-basis: 100%;
      padding: 80px 0 36px;
    }
    .earth-row-img{
      display: none;
    }
    .main-box h1{
      font-size: 36px;
      font-weight: bold;
      color: #000;
    }
    .case-box .int-flex-box-item{
      width: 100%;
      max-width: 100%;
      flex-basis: 100%;
    }
    .case-box .int-flex-box-item>div{
      flex-direction: column;
      align-items: center;
    }
    .case-box .int-flex-box-span {
      padding: 48px 26px 0;
    }
  }
</style>