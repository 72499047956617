<template>
  <div>
    <Menu></Menu>
    <div class="main-box ">
      <div class="earth-row">
        <div>
          <h1>APP开发</h1>
          <p>科缔团队多年来一直专注于手机监控软件的研发，至今开发的手机视频监控软件不下百款，具有丰富的app软件开发经验</p>
          <div style="height: 48px; "></div>
        </div>
        <div class="earth-row-img">
          <img src="/img/img/index.png">
        </div>
      </div>
    </div>
    <div class="introduce" id="app">
      <div class="int-title-box">
        <h2 class="int-title">APP简介</h2>
        <p class="int-sub-title">
          结合物联云连接平台丰富的整合经验，提供专业APP软件开发服务，分为通用版和定制版，借助移动互联网为行业提供完整的软件解决方案
        </p>
      </div>
      <div class="int-flex-box">
        <div class="int-flex-box-item no-move no-border full-box">
          <div>
            <div class="int-flex-box-half img">
              <img src="/img/img/233796532506447872.png">
            </div>
            <div class="int-flex-box-half">
              <h3 class="int-flex-box-item-title">通用版APP服务</h3>
              <div class="int-flex-box-item-info">
                <p>
                  UEMYE物联云连接平台实现了设备端和用户端的网络点对点连接，接入公共服务器的厂家设备，为用户端提供免费的通用版app软件平台，用户可直接下载使用，真正实现端到端的物物相联
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="int-flex-box-item no-move no-border full-box">
          <div>
            <div class="int-flex-box-half">
              <h3 class="int-flex-box-item-title">定制化APP服务</h3>
              <div class="int-flex-box-item-info">
                <p>
                  在物联网应用下，除了设备，APP是设备厂家获得消费者青睐的重要因素，是维系设备厂商和消费者之间的纽带。
                </p>
                <p>
                  借助多年手机视频监控软件研发累积经验，我们采用敏捷的开发快速应对客户需求，提供高质量的APP软件定制开发服务。在这些产品和解决方案中提供如用户界面框架，多媒体交互，产品功能优化等核心技术。
                </p>
              </div>
            </div>
            <div class="int-flex-box-half img">
              <img src="/img/img/233796532506447872.png">
            </div>
          </div>
        </div>
        <div class="int-flex-box-item no-move no-border full-box">
          <div>
            <div class="int-flex-box-half img">
              <img src="/img/img/233796532506447872.png">
            </div>
            <div class="int-flex-box-half">
              <h3 class="int-flex-box-item-title">自主开发</h3>
              <div class="int-flex-box-item-info">
                <p>
                  提供SDK及说明文档，有APP开发能力的企业可自行根据SDK自己设计开发应用。
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="introduce" id="function">
      <div class="int-title-box" id="advantage">
        <h2 class="int-title">核心功能</h2>
      </div>
      <div class="int-flex-box advantage">
        <div class="int-flex-box-item">
          <div>
            <div class="int-flex-box-span">
              <h3 class="int-flex-box-item-title">账号系统</h3>
              <p class="int-flex-box-item-info">注册登录，管理账号，设备列表云端存储，信息安全不丢失</p>
            </div>
            <div class="int-flex-box-img">
              <img src="/img/img/001.png">
            </div>
          </div>
        </div>
        <div class="int-flex-box-item">
          <div>
            <div class="int-flex-box-span">
              <h3 class="int-flex-box-item-title">设备管理</h3>
              <p class="int-flex-box-item-info">增删查改，提供局域网设备搜索，二维码添加等实用功能，方便用户使用</p>
            </div>
            <div class="int-flex-box-img">
              <img src="/img/img/001.png">
            </div>
          </div>
        </div>
        <div class="int-flex-box-item">
          <div>
            <div class="int-flex-box-span">
              <h3 class="int-flex-box-item-title">设备配置</h3>
              <h3 class="int-flex-box-item-info">客户端可直接配置设备，无需到设备边上处理，直接远程配置各自参数</h3>
            </div>
            <div class="int-flex-box-img">
              <img src="/img/img/001.png">
            </div>
          </div>
        </div>
        <div class="int-flex-box-item">
          <div>
            <div class="int-flex-box-span">
              <h3 class="int-flex-box-item-title">实时预览</h3>
              <h3 class="int-flex-box-item-info">自主研发高性能视频解码器，视频播放清晰流畅，还可抓拍录像，多画面播放随意切换</h3>
            </div>
            <div class="int-flex-box-img">
              <img src="/img/img/001.png">
            </div>
          </div>
        </div>
        <div class="int-flex-box-item">
          <div>
            <div class="int-flex-box-span">
              <h3 class="int-flex-box-item-title">语音对讲</h3>
              <h3 class="int-flex-box-item-info">对支持对讲的设备进行实时语音对讲，独有去噪处理，如同电话沟通交流</h3>
            </div>
            <div class="int-flex-box-img">
              <img src="/img/img/001.png">
            </div>
          </div>
        </div>
        <div class="int-flex-box-item">
          <div>
            <div class="int-flex-box-span">
              <h3 class="int-flex-box-item-title">录像回放</h3>
              <h3 class="int-flex-box-item-info">可回放本地录像及远程录像（存储在设备端），随时随地调取录像</h3>
            </div>
            <div class="int-flex-box-img">
              <img src="/img/img/001.png">
            </div>
          </div>
        </div>
        <div class="int-flex-box-item">
          <div>
            <div class="int-flex-box-span">
              <h3 class="int-flex-box-item-title">报警推送</h3>
              <h3 class="int-flex-box-item-info">报警消息云端推送并保留记录，实时掌控前端动态</h3>
            </div>
            <div class="int-flex-box-img">
              <img src="/img/img/001.png">
            </div>
          </div>
        </div>
        <div class="int-flex-box-item">
          <div>
            <div class="int-flex-box-span">
              <h3 class="int-flex-box-item-title">视频云存储</h3>
              <h3 class="int-flex-box-item-info">提供云存储服务，配置视频保存云端，提供各种套餐选择，满足不同需求</h3>
            </div>
            <div class="int-flex-box-img">
              <img src="/img/img/001.png">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="introduce" id="sdk">
      <div class="int-title-box">
        <h2 class="int-title">SDK下载</h2>
        <!-- <p class="int-sub-title">
          深耕行业多年，我们已发展为全球领先的 IoT 音视频平台。专业为您提供完善的产品服务
        </p> -->
      </div>
      <div class="int-flex-box case-box">
        <div class="int-flex-box-item thr-box">
          <div>
            <div class="int-flex-box-img">
              <img src="/img/img/word.png">
            </div>
            <div class="int-flex-box-span">
              <h3 class="int-flex-box-item-title">安卓SDK</h3>
              <div class="int-flex-box-item-info">
                <a href="http://docs.kdzn.top">查看文档></a>
              </div>
            </div>
          </div>
        </div>
        <div class="int-flex-box-item thr-box">
          <div>
            <div class="int-flex-box-img">
              <img src="/img/img/word.png">
            </div>
            <div class="int-flex-box-span">
              <h3 class="int-flex-box-item-title">iOS SDK</h3>
              <div class="int-flex-box-item-info">
                <a href="http://docs.kdzn.top">查看文档></a>
              </div>
            </div>
          </div>
        </div>
        <div class="int-flex-box-item thr-box" id="appdownload">
          <div>
            <div class="int-flex-box-img">
              <img src="/img/img/1686551296355029674_88922.png">
            </div>
            <div class="int-flex-box-span">
              <h3 class="int-flex-box-item-title">KDView</h3>
              <div class="int-flex-box-item-info">
                <a download="文档V2.0.1.pdf" href="/files/KDView V2.0.1.pdf">查看文档></a>
              </div>
              <div class="int-flex-box-item-info">
                <a target="_blank" href="http://ota.kdzn.top/app/download.html?android=1000000261&ios=2000000288">下载></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Foot></Foot>
  </div>
</template>

<script>
import Menu from "@/pages/Dashboard/Menu.vue";
import Foot from "@/pages/Dashboard/Foot.vue";
export default {
  components:{
    Menu : Menu,
    Foot : Foot,
  },
  data() {
    return {
    };
  },
  mounted() {
  },
  methods : {
  }
};
</script>
<style scoped>
  body, h2, h3, h4, h5, h6, .h2, .h3, .h4, a, p{
    font-weight: 400 !important;
  }
  .case-box .int-flex-box-item-info a{
    color: #4d85ff;
    font-weight: 600;
    text-decoration: none;
  }
  .case-box .int-flex-box-img img{
    /*width: auto;*/
    width: 32px;
    height: 32px;
  }
  .case-box .int-flex-box-item-info p{
    margin: 4px 0;
  }
  .case-box .int-flex-box-item-info{
    font-size: 16px;
    font-family: "PingFang SC";
    line-height: 26px;
    color: rgb(96,98,102);
    margin: 0;
  }
  .case-box .int-flex-box-item-title{
    font-size: 20px;
    height: 30px;
    margin: 0;
    margin-bottom: 12px;
  }
  .case-box .int-flex-box-img{
    max-width: 300px;
    min-width: 300px;
  }
  .case-box .int-flex-box-span{
    display: flex;
    flex: 1 1 auto;
    padding: 16px;
    flex-direction: column;
    padding-left: 0;
  }
  .case-box .int-flex-box-item>div:hover{
    top: -10px;
    box-shadow: 0 -2px 10px 0 hsl(225deg 3% 46% / 3%), 0 14px 20px 0 hsl(225deg 3% 46% / 18%);
  }
  .case-box .int-flex-box-item>div{
    align-items: flex-start;
    display: flex;
    background-color: rgb(255, 255, 255);
    height: 172px;
    /*height: 100%;*/
    margin: 0px auto;
    box-shadow: 0 4px 15px 0 rgb(37 60 126 / 12%);
    border-radius: 8px;
    position: relative;
    transition: top .3s, box-shadow .3s;
    top: 0;
  }
  .case-box .int-flex-box-item.full-box{
    max-width: 100%;
    flex-basis: 100%;
  }
  .case-box .int-flex-box-item{
    padding: 12px;
    flex-basis: 50%;
    flex-grow: 0;
    flex-shrink: 0;
    max-width: 50%;
    box-sizing: border-box;
  }
  .case-box.int-flex-box{
    /*padding-bottom: 72px;*/
    margin-left: -12px;
    margin-right: -12px;
    margin-top: -12px;
    margin-bottom: 12px;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    align-items: stretch;

    justify-content: center;
  }
  .case-box .int-flex-box-item.thr-box>div{
    display: flex;
    flex-direction: column;
    padding: 48px 32px 0px;
  }
  .case-box .int-flex-box-item.thr-box{
    max-width:  33%;
    flex-basis: 33%;
  }
  div.introduce{
    margin: 72px auto;
    /*margin: 72px 0;*/
    padding: 0 72px;
    max-width: 1200px;
  }
  .advantage .int-flex-box-img{
    max-width: 200px;
    min-width: 200px;
  }
  .advantage .int-flex-box-img img {
    width: 200px;
  }
  .info-item-box p{
    margin: 0 0 20px 0;
  }
  .int-flex-box-list-item{
    align-items: stretch !important;
    flex-direction: row !important;
  }
  .info-item-box img{
    margin: 42px 0;
  }
  .info-item-box{
    box-sizing: border-box;
    padding: 58px 36px 48px;
    background-color: #ffff;
    flex-basis: 60%;
    flex-grow: 1;
    flex-shrink: 0;
    /*max-width: ;*/
  }
  .list-item-box{
    box-sizing: border-box;
    padding: 58px 0;
    background-color: #f6fafe;
    flex-basis: 40%;
    flex-grow: 0;
    flex-shrink: 0;
    max-width: 360px;
    min-height: 600px;
  }
  .list-item-box .list-item-list {
    cursor: pointer;
    padding: 0 54px;
    line-height: 48px;
    font-size: 16px;
    width: 100%;
    box-sizing: border-box;
  }
  .list-item-box .list-item-list.selected {
    background: #025dff;
    color: #ffffff;
  }
  .int-flex-box-half .int-flex-box-item-title{
    font-size: 36px;
    line-height: 46px;
    margin-bottom: 36px;
    height: unset;
  }
  .int-flex-box-half.img{
    text-align: center;
  }
  img{
    max-width: 100%;
  }
  .int-flex-box-half{
    box-sizing: border-box;
    padding: 0 36px;
    flex-basis: 50%;
    flex-grow: 0;
    flex-shrink: 0;
    max-width: 50%;
  }
  .int-flex-box-img img{
    width: 300px;
  }
  .int-flex-box-item-info p{
    margin: 4px 0;
  }
  .int-flex-box-item-info{
    font-size: 16px;
    font-family: "PingFang SC";
    line-height: 26px;
    color: rgb(96,98,102);
    margin: 0;
  }
  .int-flex-box-item-title{
    font-size: 20px;
    height: 30px;
    margin: 0;
    margin-bottom: 12px;
  }
  .int-flex-box-img{
    max-width: 300px;
    min-width: 300px;
  }
  .int-flex-box-span{
    display: flex;
    flex: 1 1 auto;
    padding: 54px 16px 54px 40px;
    flex-direction: column;
  }
  .int-flex-box-item>div:hover{
    top: -10px;
    box-shadow: 0 -2px 10px 0 hsl(225deg 3% 46% / 3%), 0 14px 20px 0 hsl(225deg 3% 46% / 18%);
  }
  .int-flex-box-item.no-move>div:hover{
    top: 0;
    box-shadow: 0 4px 15px 0 rgb(37 60 126 / 12%);
  }
  .int-flex-box-item.no-border>div{
    border: 0 !important;
    box-shadow: none !important;
  }
  .int-flex-box-item>div{
    display: flex;
    background-color: rgb(255, 255, 255);
    min-height: 240px;
    height: 100%;
    margin: 0px auto;
    box-shadow: 0 4px 15px 0 rgb(37 60 126 / 12%);
    border-radius: 8px;
    position: relative;
    transition: top .3s, box-shadow .3s;
    top: 0;
    justify-content: center;
    align-items: center;
  }
  .int-flex-box-item.full-box{
    max-width: 100%;
    flex-basis: 100%;
  }
  .int-flex-box-item{
    padding: 12px;
    flex-basis: 50%;
    flex-grow: 0;
    flex-shrink: 0;
    max-width: 50%;
    box-sizing: border-box;
  }
  .int-flex-box{
    margin-left: -12px;
    margin-right: -12px;
    margin-top: -12px;
    margin-bottom: 12px;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    align-items: center;
  }
  .int-title-box{
    text-align: center;
    padding: 0 12px 60px;
  }
  .int-sub-title{
    margin-top:24px;
    color: rgb(96, 98, 102);
    font-size: 16px;
    line-height: 26px;
    height: 26px;
    margin-bottom: 0;
  }
  .int-title{
    line-height: 50px;
    height: 50px;
    margin: 0;
    font-size: 40px;
    font-weight: 700;
  }
  .earth-row{
    max-width: 1300px;
    display: flex;
    margin: 0 auto;
    height: 500px;
  }
  .earth-row img{
    width: 100%;
  }
  .earth-row p{
    color: #606266;
    line-height: 26px;
  }

  .earth-row>div{
    align-items:center;
    padding: 0 12px;
    width: 50%;
    align-self: center;
    box-sizing: border-box;
    /*padding-left: 12px;*/
    /*padding-right: 12px;*/
    flex-basis: 50%;
    flex-grow: 0;
    flex-shrink: 0;
    max-width: 50%;
  }
  .main-box p{
    margin: 32px 0 42px;
    font-size: 16px;
  }
  .main-box h1{
    line-height: 66px;
    font-size: 56px;
    margin: 0;
    font-weight: bold;
    color: #000;
    /*margin-top: 64px;*/
  }
  .main-box{
    box-shadow: inset 0 0 50px rgb(0 0 0 / 5%);
    box-sizing: border-box;
    height: 500px;
    width: 100%;
    /*padding: 0 72px;*/
    margin: 0 auto;
    background-color: #eef2f6;
    clear: both;
    background-repeat: no-repeat;
    background-size: cover;
    flex-wrap: wrap;
    font-family: PingFang-SC-Regular, "PingFang SC", "Helvetica Neue", Helvetica, Arial, "Microsoft Yahei", "Hiragino Sans GB", "Heiti SC", "WenQuanYi Micro Hei", sans-serif;
  }
  @media screen and (max-width: 1023px){
    .case-box .int-flex-box-item.thr-box{
      max-width: 100%;
      flex-basis: 100%;
    }
  .case-box .int-flex-box-item.thr-box>div{
    align-items: flex-start;
    min-height: 80px;
  }
    div.introduce{
      padding: 0 32px;
    }
    .int-flex-box-half {
        box-sizing: border-box;
        flex-basis: 100%;
        max-width: 100%;
        padding: 0;
        flex-grow: 0;
        flex-shrink: 0;
    }
    .info-item-box{
      padding: 12px 36px;
    }
    .list-item-box{
      padding: 12px 0;
    }
    .init-title-box{
      height: 150px;
    }
    .main-box p {
      margin: 24px 0;
    }
    .main-box {
      padding: 0 12px;
      height: unset;
    }
    .earth-row{
      height: unset;
    }
    .earth-row>div{
      width: 100%;
      max-width: 100%;
      flex-basis: 100%;
      padding: 80px 0 36px;
    }
    .earth-row-img{
      display: none;
    }
    .main-box h1{
      font-size: 36px;
      font-weight: bold;
      color: #000;
    }
    .int-flex-box-item{
      width: 100%;
      max-width: 100%;
      flex-basis: 100%;
    }
    .int-flex-box-item>div{
      flex-direction: column;
      align-items: center;
    }
  }
  @media screen and (max-width: 1023px){
    .case-box .int-flex-box-item{
      width: 100%;
      max-width: 100%;
      flex-basis: 100%;
    }
    .case-box .int-flex-box-item>div{
      flex-direction: column;
      align-items: center;
    }
  }
</style>