import axios from 'axios'
import { getLanguage, detectOS, nPw } from "@/common/tools.js"

const serviceMode = "offc" // offc | test
// const serviceMode = "test" // offc | test

const ERROR_CODE = {
    "401" : "http_error_514",
    "405" : "http_error_514",
    "518" : "http_error_514",
    "400" : "invalid",
    "500" : "http_error_500",
    "406" : "error_code_406",
    "513" : "http_error_513",
}

axios.defaults.withCredentials = true //开启后服务器才能拿到cookie
axios.defaults.crossDomain = true;//配置axios跨域
axios.defaults.headers.post['Content-Type'] = 'application/json; charset=UTF-8'//配置默认请求头
const testService = "https://open-oiuo-cn1.kdzn.top/api"
const offcialService = "https://open-oiuo-cn1.kdzn.top/api"
let reqURL = ""
if (serviceMode === "test"){
    reqURL = testService;
} else if (serviceMode === "offc") {
    reqURL = offcialService;
}

var conf = {
    _client_id  : "web",
    _client_type: 6,
    _custom_flag: "aMTcxOTk4ODM1NDIxNzA2",
}

// 发起请求
function postData (api, data, api_ver = "1.0.2"){
    const url = reqURL + api
    const apiData ={
        "params": data,
        "tag"   : "",
        "request":{
            "language" : getLanguage(),
            "app_key"  : conf._custom_flag,
            "acc_token": localStorage.token ?localStorage.token :"",
            "api_ver"  : api_ver,
            "auth_type": 1,
        }
    }
    return axios.post(url, apiData)
        .then(resp => {
            if (serviceMode === "test") {
                console.log(`${api} POST 参数: `, apiData, `回调: `, resp.data)
            }
            return resp.data
        })
        .catch(err => {
            if (serviceMode === "test") {
                console.log(`${api} POST 参数: `, apiData)
                console.error(`${api} POST 回调报错: `, err)
            }
            var errorCode = err.message.split(" ")[err.message.split(" ").length-1];
            if (ERROR_CODE[errorCode]) {
                throw new Error (ERROR_CODE[errorCode])
            } else {
                throw new Error ("unknown_error")
            }
        });
}
// 不封装额外的数据
// function postDataWithoutOhter (api, data){
//     if (serviceMode === "test") {
//         console.log(`${api} POST 参数: `, data)
//     }
//     const url = reqURL + api
//     const apiData = data
//     return axios.post(url, apiData)
//         .then(resp => {
//             if (serviceMode === "test") {
//                 console.log(`${api} POST 回调: `, resp.data)
//             }
//             if (ERROR_CODE[resp.data.code]) {
//                 throw new Error (ERROR_CODE[resp.data.code])
//             } else {
//                 return resp.data
//             }
//         })
//         .catch(err => {
//             if (serviceMode === "test") {
//                 console.error(`${api} POST 回调报错: `, err)
//             }
//             var errorCode = err.message.split(" ")[err.message.split(" ").length-1];
//             if (ERROR_CODE[errorCode]) {
//                 throw new Error (ERROR_CODE[errorCode])
//             } else {
//                 throw new Error ("unknown_error")
//             }
//         });
// }

var dataToSendData = function(data, keyObj){
    if(!keyObj || Object.keys(keyObj).length == 0){
        console.log("请输入keyObj, 不然不做处理");
        return data;
    }
    var sendData = {};
    if(keyObj.strKey){
        keyObj.strKey.forEach(function(key){
            sendData[key] = data[key] ? data[key].toString() : "";
        });
    }
    if(keyObj.numKey){
        keyObj.numKey.forEach(function(key){
            if(data[key] && isNaN(data[key])){
                console.log(key+"需要是个数字");
                sendData[key] = 0;
            }else{
                sendData[key] = data[key] ? parseInt(data[key]) : 0;
            }
        });
    }
    if(keyObj.numArrayKey){
        keyObj.numArrayKey.forEach(function(key){
            sendData[key] = [];
            data[key].forEach(function(num){
                sendData[key].push(parseInt(num));
            });
        });
    }
    if(keyObj.defaultKey){
        keyObj.defaultKey.forEach(function(key){
            sendData[key] = data[key];
        });
    }
    return sendData;
}

// 校验参数
var checkData = function(data, valueType){
    // console.log("origin data: ", JSON.stringify(data))
    var sendData = {}
    for(var key in valueType){
        switch(valueType[key]){
            case "string":
            case "str":
                sendData[key] = data[key] ?(data[key]+"") :""
                break
            case "number":
            case "int":
            case "num":
                if(data[key] && isNaN(data[key])){
                    console.error("error param :", key)
                    break
                }
                sendData[key] = data[key] ?parseInt(data[key]) :0
                break
            case "numArray":
            case "intArray":
                sendData[key] = [];
                data[key].forEach(function(num){
                    if(isNaN(num)){
                        console.error("error param :", key)
                        return
                    }
                    sendData[key].push(parseInt(num));
                });
                break
            default:
                sendData[key] = data[key]
                break
        }
    }
    return sendData
}

// console.info(postDataWithoutOhter)
let api = {
    uploadFile(File){
        let url = reqURL + "/open/file/upload";
        return axios.post(url, File)
            .then(resp => {
                if (serviceMode === "test") {
                    console.log(`${api} POST 回调: `, resp.data)
                }
                return resp.data
            })
            .catch(err => {
                if (serviceMode === "test") {
                    console.error(`${api} POST 回调报错: `, err)
                }
                var errorCode = err.message.split(" ")[err.message.split(" ").length-1];
                if (ERROR_CODE[errorCode]) {
                    throw new Error (ERROR_CODE[errorCode])
                } else {
                    throw new Error ("unknown_error")
                }
            });
    },
    /*--- USER(/user) ---*/
    // user: {
        // 登入
        login(data){
            return postData("/user/login", {
                "device_system": "web",
                "device_id"    : "",
                "device_model" : detectOS(),
                "user_id"      : data.user_id,
                "user_password": nPw(data.user_id, data.user_password)
            })
        },
        // 登出
        logout(){
            return postData("/user/logout", {})
        },
        // 用户信息查询
        userInfo() {
            return postData("/user/userinfo", {})
        },
        getUserList(data){
            var sendData = dataToSendData(data, {
                strKey : [],
                numKey : ["page_index", "page_size", "user_state"],
                numArrayKey : []
            });
            if(sendData.page_index){
                sendData.page_no = sendData.page_index
            }
            sendData.state = sendData.user_state === -1 ?0 :sendData.user_state

            return postData("/user/query", sendData);
        },
        //修改密码 (/user/password/modify)
        changePassword(data){
            return postData("/user/password/modify", {
                user_id      : data.user_id,
                old_password : nPw(data.user_id, data.old_passwd),
                new_password : nPw(data.user_id, data.new_passwd),
            });
        },
        // 获取菜单列表
        getMenu(data){
            return postData("/user/menu/query",
                checkData(data, {
                    "type" : "int"
                })
            );
        },
        // 添加菜单
        addMenu(data){
            return postData(
                "/user/menu/add",
                checkData(data, {
                    "menu_id"         : "int",
                    "menu_parent_id"  : "int",
                    "menu_key"        : "string",
                    "menu_name"       : "string",
                    "menu_cn_name"    : "string",
                })
            );
        },
        //修改菜单 (/user/menu/modify)
        editMenu(data){
            return postData(
                "/user/menu/modify",
                checkData(data, {
                    "menu_id"         : "int",
                    "menu_key"        : "string",
                    "menu_name"       : "string",
                    "menu_cn_name"    : "string",
                })
            );
        },
        // 删除菜单 (user/menu/delete)
        deleteMenu(data){
            return postData(
                "/user/menu/delete",
                checkData(data, {
                    "menu_id"         : "int",
                })
            );
        },
        //获取角色信息及菜单权限(10007)
        getRole(data){
            return postData("/user/role/query", data);
        },
        addRole(data){
            data.state = parseInt(data.state)

            return postData("/user/role/add", data);
        },
        // 添加菜单角色信息及菜单权限（物业类型企业）(10008)
        delRole (data){
            return postData("/user/role/delete", data);
        },
        //获取企业列表(10101)
        getCompanyList(data){
            var sendData = dataToSendData(data, {
                strKey : [],
                numKey : ["page_index", "page_size", "page_no", "only_sub"],
                numArrayKey : []
            });
            if (!isNaN(sendData.page_index)){
                sendData.page_no = sendData.page_index
            }
            
            return postData("/user/company/query", sendData);
        },
        // 查询服务器列表
        getServerList (data){
            var sendData = dataToSendData(data, {
                strKey : ["sys","env", "region", "zone","appid"],
                numKey : ["state","type","page_no","page_size"],
                numArrayKey : []
            });

            // sendData.page_no = sendData.page_index;
            return postData("/discovery/srv/query", sendData);
        },
        //设置企业列表(10102)
        setCompany(data){

            var sendData = dataToSendData(data, {
                strKey : ["dk", "comp_name", "country", "province","city","area", "comp_addr", "latitude","longitude","comp_person",
                "phone_area", "phone","remark","comp_short_name", "sys"],
                numKey : ["comp_id","sort"],
                numArrayKey : []
            });
            // data.comp_type = parseInt(data.comp_type)
            if(data.opcode){
                switch(data.opcode){
                    case 1:
                    case "1":
                        return postData("/user/company/add", sendData);
                    case 2:
                    case "2":
                        return postData("/user/company/modify", sendData);
                    case 3:
                    case "3":
                        return postData("/user/company/delete", sendData);
                    default:
                        console.log("error opcode", sendData.opcode)
                }   
            }
        },
        //获取APP(10103)
        getApp(data){
            var sendData = dataToSendData(data, {
                strKey : [],
                numKey : ["page_no", "page_size"],
                numArrayKey : []
            });
            return postData("/user/app/query", sendData);
        },
        //获取APP(10103)
        getAppDetailId(data){
            var sendData = dataToSendData(data, {
                strKey : [],
                numKey : [],
                numArrayKey : []
            });
            return postData("/user/appid/get", sendData);
        },
        setBaseApp(data){
            var sendData = dataToSendData(data, {
                strKey : ["name"],
                numKey : ["id", "comp_id"],
                numArrayKey : []
            });

            if(data.opcode){
                switch(data.opcode){
                    case 1:
                    case "1":
                        return postData("/user/app/add", sendData);
                    case 2:
                    case "2":
                        return postData("/user/app/set", sendData);
                    case 3:
                    case "3":
                        return postData("/user/app/delete", sendData);
                    default:
                        console.log("error opcode", data.opcode)
                }   
            }
        },
        //设置APP(10104)
        setApp(data){
            var sendData = dataToSendData(data, {
                strKey : ["opcode", "app_id", "app_name", "app_packet", "comp_id"],
                numKey : ["client_type"],
                numArrayKey : []
            });

            if(data.opcode){
                switch(data.opcode){
                    case 1:
                    case "1":
                        return postData("/user/app/add", sendData);
                    case 2:
                    case "2":
                        return postData("/user/app/modify", sendData);
                    case 3:
                    case "3":
                        return postData("/user/app/delete", sendData);
                    default:
                        console.log("error opcode", data.opcode)
                }   
            }
        },
        // 设置APP和支付配置
        setAppPaymentRela(data){
            var sendData = dataToSendData(data, {
                strKey : ["app_id"],
                numKey : [],
                numArrayKey : []
            });
            sendData.ids = data.ids;

            return postData("/pay/appid/set", sendData);
        },
        // 获取APP是否限制商品(10403)
        setAppProduct(data){
            var sendData = dataToSendData(data, {
                strKey : ["app_id"],
                numKey : [],
                numArrayKey : []
            });
            sendData.products = data.products;

            return postData("/mall/product/app/set", sendData);
        },
        // 提交导入企业设备信息
        uploadCompDevInfo (data){
            var sendData = dataToSendData(data, {
                strKey : ["file_url"],
                numKey : ["file_dev_type"],
                numArrayKey : []
            });
            sendData.product_list = data.product_list;

            return postData("/mall/device/import", sendData);
        },
        // 查询企业设备信息配对信息(10503)
        getCompDevPairInfo(data){
            var sendData = dataToSendData(data, {
                strKey : ["import_start_time", "import_end_time"],
                numKey : ["file_dev_type", "mapping_state", "page_index", "page_size"],
                numArrayKey : []
            });
            sendData.product_list = data.product_list;
            sendData.page_no = data.page_index;
            return postData("/mall/device/getmap", sendData);
        },
        // 查询导入企业设备信息列表(10502)
        getCompDevInfoXls(data){
            var sendData = dataToSendData(data, {
                strKey : ["import_start_time", "import_end_time"],
                numKey : ["file_dev_type", "state", "page_index", "page_size"],
                numArrayKey : []
            });
            sendData.product_list = data.product_list;
            sendData.page_no = data.page_index;
            return postData("/mall/device/getlist", sendData);
        },
        //获取阿里云OSS存储配置信息(10202)
        getCloudOssInfo(data){
            var sendData = dataToSendData(data, {
                strKey : ["app_id"],
                numKey : ["page_no", "page_size"],
                numArrayKey : []
            });

            return postData("/oss/appid/query", sendData);
        },
        //获取APP(10204)
        getCloudOssSet(data){
            var sendData = dataToSendData(data, {
                strKey : ["app_id"],
                numKey : ["page_no", "page_size"],
                numArrayKey : []
            });

            return postData("/oss/appid/query", sendData);
        },
        //获取APP(10205)
        setCloudOssSet(data){
            var sendData = data;

            return postData("/oss/appid/set", sendData);
        },
        // 获取商品分类列表(10101)
        getGoodClass(){
            return postData("/mall/product/category/query", {});
        },
        // 获取商品列表(10107)
        getGood(data){
            var sendData = dataToSendData(data, {
                strKey : ["category_id","seller_id", "app_id"],
                numKey : [ "page_index", "page_size"],
                numArrayKey : []
            });
            sendData.page_no = sendData.page_index
            sendData.owner_id = sendData.seller_id
            // sendData.app_id = ""
            return postData("/mall/product/query", sendData);
        },

        //获取支付配置(40201)
        getPaymentSet (data){
            var sendData = dataToSendData(data, {
                strKey : ["app_id"],
                numKey : ["type"],
                numArrayKey : []
            });
            sendData.page_no = 0
            sendData.page_size = 0;

            return postData("/pay/appid/query", sendData);
        },
        // 获取商品属性列表(10103)
        getAttrName (data){
            var sendData = dataToSendData(data, {
                strKey : [],
                numKey : ["category_id"],
                numArrayKey : []
            });
            sendData.id = sendData.category_id + ""
            
            return postData("/mall/product/category/attrs", sendData);
        },

        // 获取购买订单(10202)
        getBuyList(data){
            var sendData = dataToSendData(data, {
                strKey : ["app_id", "platform_order_id", "user_id", "order_id", "umid", "order_by", "pay_id"],
                numKey : ["comp_id", "page_no", "page_size", "order_state", "channel", "state", "gmt_start", "gmt_end"],
                numArrayKey : []
            });
            sendData.uid = sendData.umid
            // sendData.page_no = sendData.page_index
            // sendData.pay_id = sendData.platform_order_id
            sendData.app_ids = data.app_ids

            return postData("/mall/order/query", sendData);
        },
        //增加、修改、删除用户(10010)
        setUserList(data){
            var sendData = dataToSendData(data, {
                strKey : ["user_id", "user_password", "user_name", "user_email", "user_phone", "validity_time", "remark"],
                numKey : [ "comp_id","user_state", "company_id", "user_type", "role_id", "gmt_validity"],
                numArrayKey : []
            });
            sendData.user_phone_area = data.user_phone_area ? data.user_phone_area : "+86";

            if(data.opcode){
                switch(data.opcode){
                    case 1:
                    case "1":
                        // sendData.comp_id = sendData.company_id
                        sendData.user_password = nPw(data.user_id, data.user_password)
                        return postData("/user/add", sendData);
                    case 2:
                    case "2":
                        return postData("/user/modify", sendData);
                    case 3:
                    case "3":
                        return postData("/user/delete", sendData);
                    default:
                        console.log("error opcode", data.opcode)
                }   
            }
        },
        //获取APP(10203)
        setCloudOssInfo(data){
            var sendData = dataToSendData(data, {
                strKey : ["platform_id", "comp_id","platform_name", "zone", "platform_app_key", "platform_app_secret", "platform_end_point", 
                    "platform_bucket", "platform_file_prefix", "platform_store_path"],
                numKey : ["type_id", "platform_port"],
                numArrayKey : []
            });

            if(data.opcode){
                switch(data.opcode){
                    case 1:
                    case "1":
                        return postData("/oss/config/add", sendData);
                    case 2:
                    case "2":
                        return postData("/oss/config/modify", sendData);
                    case 3:
                    case "3":
                        return postData("/oss/config/delete", sendData);
                    default:
                        console.log("error opcode", data.opcode)
                }   
            }
        },
        // 修改菜单角色信息及菜单权限（物业类型企业）(10008)
        editRole(data){
            // data.role_state = parseInt(data.role_state)
            return postData("/user/role/modify", data);
        },
          
        // 增加、修改、删除商品(10108)
        setGood(data){
            var sendData = dataToSendData(data, {
                strKey : ["category_id", "product_id","opcode", "product_name", "product_img", "seller_id"],
                numKey : ["sort_num", "comp_id"],
                numArrayKey : []
            });
            if(data.opcode){
                switch(data.opcode){
                    case 1:
                    case "1":
                        return postData("/mall/product/add", sendData);
                    case 2:
                    case "2":
                        return postData("/mall/product/modify", sendData);
                    case 3:
                    case "3":
                        return postData("/mall/product/delete", sendData);
                    default:
                        console.log("error opcode", data.opcode)
                }   
            }
        },

        // 增加、修改、删除商品库存(10109)
        setGoodStock(data){
            var sendData = dataToSendData(data, {
                strKey : ["product_id", "stock_id", "opcode", "stock_name", "stock_img", "fee", "fact_fee", "usd_fee", "fact_usd_fee"],
                numKey : ["stock_state", "limit_stock_num", "stock_num","sort_num", "month", "only", "order_id"],
                numArrayKey : []
            });

            if(data.opcode){
                switch(data.opcode){
                    case 1:
                    case "1":
                        return postData("/mall/stock/add", sendData);
                    case 2:
                    case "2":
                        return postData("/mall/stock/modify", sendData);
                    case 3:
                    case "3":
                        return postData("/mall/stock/delete", sendData);
                    default:
                        console.log("error opcode", data.opcode)
                }   
            }
        },
        // 配置商品属性信息(10110)
        setGoodAttr(data){
            var sendData = dataToSendData(data, {
                strKey : ["id"],
                numKey : [],
                numArrayKey : []
            });
            sendData.attrs = data.attrs;
            return postData("/mall/product/attr", sendData);
        },
        // 配置商品库存属性信息(10111)
        setGoodStockAttr(data){
            var sendData = dataToSendData(data, {
                strKey : ["id"],
                numKey : [],
                numArrayKey : []
            });
            sendData.attrs = data.attrs;
            return postData("/mall/stock/attr", sendData);
        },
        uploadIotOrder(data){
            var sendData = dataToSendData(data, {
                strKey: ["file_url", "start_time" ,"end_time"],
                numKey : ["type"],
                numArrayKey : []
            })
            sendData.attrs = data.attrs;
            return postData("/iot/data/file/update", sendData);
        },
        getIotOrderXlsx(data){
            var sendData = dataToSendData(data, {
                strKey: ["import_start_time", "import_end_time"],
                numKey : ["state"],
                numArrayKey : []
            })
            sendData.attrs = data.attrs;
            return postData("/iot/data/file/query", sendData);
        },
        //新增支付配置(40202)
        addPaymentSet (data){
            var sendData = dataToSendData(data, {
                strKey : ["platform_app_id","comp_id","platform_public_key","platform_private_key","platform_return_url", "platform_notify_url", "remark"],
                numKey : ["platform_type", "env_type"],
                numArrayKey : []
            });
            sendData.id = data.id

            if(data.opcode){
                switch(data.opcode){
                    case 1:
                    case "1":
                        return postData("/pay/info/add", sendData);
                    case 2:
                    case "2":
                        return postData("/pay/info/modify", sendData);
                    case 3:
                    case "3":
                        return postData("/pay/info/delete", sendData);
                    default:
                        console.log("error opcode", data.opcode)
                }   
            }
        },
        authServer (data){
            var sendData = dataToSendData(data, {
                strKey : ["sys" ,"env","region" ,"zone" ,"app_id", "name", "grpc_addr", "http_addr"],
                numKey : ["state", "type"],
                numArrayKey : []
            });
            sendData.id = data.platform_id
            return postData("/discovery/srv/set", sendData);
        },
        // // 注册服务
        // authServer (data){
        //     var sendData = dataToSendData(data, {
        //         strKey : ["sys" ,"region" ,"zone" ,"hostname" ,"mac"],
        //         numKey : ["type"],
        //         numArrayKey : []
        //     });
        //     sendData.id = data.platform_id

        //     errCallBack = errCallBack 
        //         ? errCallBack 
        //         : function(data){commonErrorLayer()}

        //     return postData("/discovery/appid/add", sendData);
        // } 
        // // 查询注册服务
        // authServer (data){
        //     var sendData = dataToSendData(data, {
        //         strKey : ["sys" ,"region" ,"zone" ,"hostname" ,"mac"],
        //         numKey : ["type"],
        //         numArrayKey : []
        //     });

        //     errCallBack = errCallBack 
        //         ? errCallBack 
        //         : function(data){commonErrorLayer(data.opcode)}

        //     return postData("/discovery/appid/add", sendData);
        // }
        // 查询注册的服务
        getAuthServer (data){
            var sendData = dataToSendData(data, {
                strKey : ["sys" ,"region" ,"zone" ,"appid" ,"mac"],
                numKey : ["type"],
                numArrayKey : []
            });
            return postData("/discovery/auths/query", sendData);
        },
        appVerQuery (data){
            var sendData = dataToSendData(data, {
                strKey : ["app_id"],
                numKey : ["page_no","page_size"],
                numArrayKey : []
            });

            return postData("/ota/app/ver/query", sendData);
        },
        addAppVer (data){
            var sendData = dataToSendData(data, {
                strKey : ["ver", "app_id", "name"],
                numKey : ["force", "state"],
                numArrayKey : []
            });
            sendData.details = data.details;

            return postData("/ota/app/ver/add", sendData);
        },
        editAppVer (data){
            var sendData = dataToSendData(data, {
                strKey : ["id","ver", "app_id", "name"],
                numKey : ["force", "state"],
                numArrayKey : []
            });
            sendData.details = data.details;

            return postData("/ota/app/ver/modify", sendData);
        },
        delAppVer (data){
            var sendData = dataToSendData(data, {
                strKey : ["id"],
                numKey : [],
                numArrayKey : []
            });

            return postData("/ota/app/ver/delete", sendData);
        },

        appUrlQuery (data){
            var sendData = dataToSendData(data, {
                strKey : ["app_id"],
                numKey : ["page_no","page_size"],
                numArrayKey : []
            });

            return postData("/ota/app/url/query", sendData);
        },
        addAppUrl (data){
            var sendData = dataToSendData(data, {
                strKey : ["app_id", "name"],
                numKey : ["state"],
                numArrayKey : []
            });
            sendData.details = data.details;

            return postData("/ota/app/url/add", sendData);
        },
        editAppUrl (data){
            var sendData = dataToSendData(data, {
                strKey : ["id", "app_id", "name"],
                numKey : ["state"],
                numArrayKey : []
            });
            sendData.details = data.details;

            return postData("/ota/app/url/modify", sendData);
        },
        delAppUrl (data){
            var sendData = dataToSendData(data, {
                strKey : ["id"],
                numKey : [],
                numArrayKey : []
            });

            return postData("/ota/app/url/delete", sendData);
        },
        // 增加、修改、删除分类(10102)
        setGoodClass(data){
            var sendData = dataToSendData(data, {
                strKey : ["opcode", "category_name", "category_img"],
                numKey : ["category_id", "parent_category_id"],
                numArrayKey : []
            });
            // return postData("10102", sendData, function(data){
            //     if(data.h.e == 200 || sendData.opcode == "2"){
            //         callback(data);
            //     }else{
            //         commonErrorLayer(sendData.opcode);
            //     }
            // });
            if(data.opcode){
                switch(data.opcode){
                    case 1:
                    case "1":
                        return postData("/mall/attr/add", sendData);
                    case 2:
                    case "2":
                        return postData("/mall/attr/modify", sendData);
                    case 3:
                    case "3":
                        return postData("/mall/attr/delete", sendData);
                    default:
                        console.log("error opcode", data.opcode)
                }   
            }
        },

        // 增加、修改、删除属性名(10104)
        setAttrName(data){
            var sendData = dataToSendData(data, {
                strKey : ["opcode", "attr_key_name", "attr_key_code"],
                numKey : ["attr_key_id", "category_id"],
                numArrayKey : []
            });
            if(data.opcode){
                switch(data.opcode){
                    case 1:
                    case "1":
                        return postData("/mall/attr/key/add", sendData);
                    case 2:
                    case "2":
                        return postData("/mall/attr/key/modify", sendData);
                    case 3:
                    case "3":
                        return postData("/mall/attr/key/delete", sendData);
                    default:
                        console.log("error opcode", data.opcode)
                }   
            }
        },
        // 增加、修改、删除属性值(10106)
        setAttrVal(data){
            var sendData = dataToSendData(data, {
                strKey : ["opcode"],
                numKey : ["attr_key_id"],
                numArrayKey : []
            });
            sendData.attr_value_list = [];
            data.attr_value_list.forEach(function(item){
                sendData.attr_value_list.push({
                    attr_value_id : parseInt(item.id),
                    attr_value_name : item.value+"",
                });
            });
            if(data.opcode){
                switch(data.opcode){
                    case 1:
                    case "1":
                        return postData("/mall/attr/value/add", sendData);
                    case 2:
                    case "2":
                        return postData("/mall/attr/value/modify", sendData);
                    case 3:
                    case "3":
                        return postData("/mall/attr/value/delete", sendData);
                    default:
                        console.log("error opcode", data.opcode)
                }   
            }
        },
        // 查询订单数据
        getOrderStatistical(data) {
            var sendData = dataToSendData(data, {
                strKey : ["start_date", "end_date"],
                numKey : ["type"],
                numArrayKey : []
            });
            sendData.app_id = data.app_id
            return postData("/mall/statis/order", sendData)
        },
        getOrderaCompStatistical(data) {
            var sendData = dataToSendData(data, {
                strKey : ["start_date", "end_date"],
                numKey : ["type"],
                numArrayKey : []
            });
            sendData.comp_id = data.comp_id
            return postData("/mall/statis/order/comp", sendData)
        },
        // 邮件-平台-添加
        addEmailPlatform(data) {
            var sendData = dataToSendData(data, {
                strKey : ["name", "zone", "smtp_addr", "userid", "password"],
                numKey : ["state", "smtp_port", "ssl"],
                numArrayKey : []
            });
            sendData.comp_id = data.comp_id
            return postData("/push/email/platform/add", sendData)
        },
        // 邮件-平台-修改
        editEmailPlatform(data) {
            var sendData = dataToSendData(data, {
                strKey : ["name", "zone", "smtp_addr", "userid", "password"],
                numKey : ["pid", "state", "smtp_port", "ssl"],
                numArrayKey : []
            });
            sendData.comp_id = data.comp_id
            return postData("/push/email/platform/modify", sendData)
        },
        // 邮件-平台-删除
        delEmailPlatform(data) {
            var sendData = dataToSendData(data, {
                strKey : [],
                numKey : ["pid"],
                numArrayKey : []
            });
            sendData.comp_id = data.comp_id
            return postData("/push/email/platform/delete", sendData)
        },
        // 邮件-平台-查询
        getEmailPlatform(data) {
            var sendData = {}
            sendData.comp_id = data.comp_id
            return postData("/push/email/platform/query", sendData)
        },
        getPushRecord (data){
            data.page_no  =data.page_index
            switch (data.type) {
                case "sms":
                    return api.getSmsRecord(data)
                case "email":
                    return api.getEmailRecord(data)
                case "msg":
                    return api.getMsgRecord(data)
            }
        },

        // 获取用户云储存使用信息(10303)
        getUserCloudInfo(data){
            var sendData = dataToSendData(data, {
                strKey : ["app_id", "uid", "user_id"],
                numKey : ["channel", "state", "page_no", "page_size", "type", "bind"],
                numArrayKey : []
            });
            if(data.order_state){
                sendData.order_state = parseInt(data.order_state)
            }
            if(!data.state && data.order_state){
                sendData.state = data.order_state
            }
            sendData.app_ids = data.app_ids


            return postData("/mall/oss/order/query", sendData);
        },

        uploadDevOrder(data){
            var sendData = dataToSendData(data, {
                strKey: ["file_url", "sys"],
                numKey : ["comp_id"],
                numArrayKey : []
            })
            sendData.attrs = data.attrs;
            return postData("/iot/dev/file/update", sendData);
        },

        getDevListXlsx(data){
            var sendData = dataToSendData(data, {
                strKey: ["import_start_time", "import_end_time"],
                numKey : ["state"],
                numArrayKey : []
            })
            sendData.attrs = data.attrs;
            return postData("/iot/dev/file/query", sendData);
        },
    // }

    _apiSend(data){
        const url = "https://open-oiuo-cn1.kdzn.top/umeye_api";
        return axios.post(url, data)
        .then(res => {
            return res.data
        })
    },
    ...conf,
}
var createApi = function(name, url, dataCheckFunc) {
    api[name] = function(data) {
        var sendData = dataCheckFunc(data)
        return postData(url, sendData)
    }
}

createApi("getOssEvent", "/oss/event/get" ,function(data){
    var sendData = dataToSendData(data, {
        strKey : ["start_time", "uid", "end_time"],
        numKey : ["is_url", "channel", "page_no", "page_size", "is_user"],
        numArrayKey : []
    });
    return sendData
})


createApi("getOtherPlatform", "/mall/otherplatform/product/query" ,function(data){
    var sendData = dataToSendData(data, {
        strKey : [],
        numKey : ["type", "order_id"],
        numArrayKey : []
    });
    return sendData
})
// 邮件-模板-添加
createApi("addEmailTemplate", "/push/email/template/add" ,function(data){
    return dataToSendData(data, {
        strKey : ["lang", "title", "body", "name", "param"],
        numKey : ["type"],
        numArrayKey : []
    });
})
// 邮件-模板-修改
createApi("editEmailTemplate", "/push/email/template/modify" ,function(data){
    return dataToSendData(data, {
        strKey : ["lang", "title", "body", "name", "param"],
        numKey : ["tid", "type"],
        numArrayKey : []
    });
})
// 邮件-模板-删除
createApi("delEmailTemplate", "/push/email/template/delete" ,function(data){
    return dataToSendData(data, {
        strKey : [],
        numKey : ["tid"],
        numArrayKey : []
    });
})
// 邮件-模板-查询
createApi("getEmailTemplate", "/push/email/template/query" ,function(data){
    return {
        comp_id : data.comp_id
    }
})
// 邮件-APP绑定-设置
createApi("setEmailAppBind", "/push/email/bindapp/set" ,function(data){
    var sendData = dataToSendData(data, {
        strKey : ["app_id"],
        numKey : [],
        numArrayKey : []
    });
    sendData.bind = data.bind
    sendData.appid_type = data.appid_type ?data.appid_type :1
    return sendData
})
// 邮件-APP绑定-查询
createApi("getEmailAppBind", "/push/email/bindapp/get" ,function(data){
    return {
        app_id : data.app_id,
        appid_type : data.appid_type,
    }
})
// 短信-平台-添加
createApi("addSmsPlatform", "/push/sms/platform/add" ,function(data){
    return dataToSendData(data, {
        strKey : ["name", "zone", "key_id", "key_secret", "sign_name"],
        numKey : ["area_type", "state", "platform"],
        numArrayKey : []
    });
})
// 短信-平台-修改
createApi("editSmsPlatform", "/push/sms/platform/modify" ,function(data){
    return dataToSendData(data, {
        strKey : ["name", "zone", "key_id", "key_secret", "sign_name"],
        numKey : ["pid", "area_type", "state", "platform"],
        numArrayKey : []
    });
})
// 短信-平台-删除
createApi("delSmsPlatform", "/push/sms/platform/delete" ,function(data){
    return dataToSendData(data, {
        strKey : [],
        numKey : ["pid"],
        numArrayKey : []
    });
})
// 短信-平台-查询
createApi("getSmsPlatform", "/push/sms/platform/query" ,function(data){
    return {
        comp_id : data.comp_id
    }
})
// 短信-模板-添加
createApi("addSmsTemplate", "/push/sms/template/add" ,function(data){
    return dataToSendData(data, {
        strKey : ["lang", "name", "code", "param", "body"],
        numKey : ["type"],
        numArrayKey : []
    });
})
// 短信-模板-修改
createApi("editSmsTemplate", "/push/sms/template/modify" ,function(data){
    return dataToSendData(data, {
        strKey : ["lang", "name", "code", "param", "body"],
        numKey : ["tid", "type"],
        numArrayKey : []
    });
})
// 短信-模板-删除
createApi("delSmsTemplate", "/push/sms/template/delete" ,function(data){
    return dataToSendData(data, {
        strKey : [],
        numKey : ["tid"],
        numArrayKey : []
    });
})
// 短信-模板-查询
createApi("getSmsTemplate", "/push/sms/template/query" ,function(data){
    return {
        comp_id : data.comp_id
    }
})
// 短信-APP绑定-设置
createApi("setSmsAppBind", "/push/sms/bindapp/set" ,function(data){
    var sendData = dataToSendData(data, {
        strKey : ["app_id"],
        numKey : [],
        numArrayKey : []
    });
    sendData.bind = data.bind
    sendData.appid_type = data.appid_type ?data.appid_type :1
    return sendData
})
// 短信-APP绑定-查询
createApi("getSmsAppBind", "/push/sms/bindapp/get" ,function(data){
    return {
        app_id : data.app_id,
        appid_type : data.appid_type,
    }
})
// 信息-平台-添加
createApi("addMsgPlatform", "/push/msg/platform/add" ,function(data){
    return dataToSendData(data, {
        strKey : ["name", "access_id", "access_key", "secret_key"],
        numKey : ["type"],
        numArrayKey : []
    });
})
// 信息-平台-修改
createApi("editMsgPlatform", "/push/msg/platform/modify" ,function(data){
    return dataToSendData(data, {
        strKey : ["name", "access_id", "access_key", "secret_key"],
        numKey : ["pid", "type"],
        numArrayKey : []
    });
})
// 信息-平台-删除
createApi("delMsgPlatform", "/push/msg/platform/delete" ,function(data){
    return dataToSendData(data, {
        strKey : [],
        numKey : ["pid"],
        numArrayKey : []
    });
})
// 信息-平台-查询
createApi("getMsgPlatform", "/push/msg/platform/query" ,function(data){
    return {
        comp_id : data.comp_id
    }
})
// 信息-模板-添加
createApi("addMsgTemplate", "/push/msg/template/add" ,function(data){
    return dataToSendData(data, {
        strKey : ["lang", "name", "title", "param", "body"],
        numKey : ["type"],
        numArrayKey : []
    });
})
// 信息-模板-修改
createApi("editMsgTemplate", "/push/msg/template/modify" ,function(data){
    return dataToSendData(data, {
        strKey : ["lang", "name", "title", "param", "body"],
        numKey : ["tid", "type"],
        numArrayKey : []
    });
})
// 信息-模板-删除
createApi("delMsgTemplate", "/push/msg/template/delete" ,function(data){
    return dataToSendData(data, {
        strKey : [],
        numKey : ["tid"],
        numArrayKey : []
    });
})
// 信息-模板-查询
createApi("getMsgTemplate", "/push/msg/template/query" ,function(data){
    return {
        comp_id : data.comp_id
    }
})
// 信息-APP绑定-设置
createApi("setMsgAppBind", "/push/msg/bindapp/set" ,function(data){
    var sendData = dataToSendData(data, {
        strKey : ["app_id"],
        numKey : [],
        numArrayKey : []
    });
    sendData.bind = data.bind
    sendData.appid_type = data.appid_type ?data.appid_type :1
    return sendData
})
// 信息-APP绑定-查询
createApi("getMsgAppBind", "/push/msg/bindapp/get" ,function(data){
    return {
        app_id : data.app_id
    }
})
// 推送-email-record查询
createApi("getEmailRecord", "/push/email/record/query" ,function(data){
    var sendData = dataToSendData(data, {
        strKey : ["app_id", "link_id"],
        numKey : ["state", "page_no", "page_size"],
        numArrayKey : []
    });
    sendData.app_ids = data.app_ids
    return sendData
})
// 推送-sms-record查询
createApi("getSmsRecord", "/push/sms/record/query" ,function(data){
    var sendData = dataToSendData(data, {
        strKey : ["app_id", "link_id"],
        numKey : ["state", "page_no", "page_size"],
        numArrayKey : []
    });
    sendData.app_ids = data.app_ids
    return sendData
})// 推送-msg-record查询
createApi("getMsgRecord", "/push/msg/record/query" ,function(data){
    var sendData = dataToSendData(data, {
        strKey : ["app_id", "link_id"],
        numKey : ["state", "page_no", "page_size"],
        numArrayKey : []
    });
    sendData.app_ids = data.app_ids
    return sendData
})
createApi("getIccDetails", "/mall/otherplatform/iccid/query",function(data){
    var sendData = dataToSendData(data, {
        strKey : ["icc_id"],
        numKey : [],
        numArrayKey : []
    });
    return sendData
})

createApi("getOtherPlatformInfo", "/mall/otherplatform/query", function(data){
    var sendData = dataToSendData(data, {
        strKey : [],
        numKey : ["type", "page_no", "page_size"],
        numArrayKey : []
    });
    return sendData
})
createApi("addOtherPlatformInfo", "/mall/otherplatform/add", function(data){
    var sendData = dataToSendData(data, {
        strKey : ["app_key" , "app_secret", "comp_id","name"],
        numKey : ["type"],
        numArrayKey : []
    });
    return sendData
})

createApi("editOtherPlatformInfo", "/mall/otherplatform/modify", function(data){
    var sendData = dataToSendData(data, {
        strKey : ["app_key" , "app_secret","comp_id","name"],
        numKey : ["type", "id"],
        numArrayKey : []
    });
    return sendData
})

createApi("delOtherPlatformInfo", "/mall/otherplatform/delete", function(data){
    var sendData = dataToSendData(data, {
        strKey : [],
        numKey : ["id"],
        numArrayKey : []
    });
    return sendData
})

createApi("bindAppOtherPlatform", "/mall/otherplatform/appbind/set", function(data){
    var sendData = dataToSendData(data, {
        strKey : ["app_id"],
        numKey : [],
        numArrayKey : ["platform_id"]
    });
    return sendData
})

createApi("getAppOtherPlatformBind", "/mall/otherplatform/appbind/get", function(data){
    var sendData = dataToSendData(data, {
        strKey : ["app_id"],
        numKey : [],
        numArrayKey : []
    });
    return sendData
})

createApi("queryPackageOrder", "/mall/package/query", function(data){
    var sendData = dataToSendData(data, {
        strKey : ["app_id", "user_id", "uid"],
        numKey : ["state", "page_no", "page_size"],
        numArrayKey : []
    });
    return sendData
})

createApi("getAllFile", "/oss/file/getall", function(data){
    var sendData = dataToSendData(data, {
        strKey : ["umid_id"],
        numKey : [],
        numArrayKey : []
    });
    return sendData
})

createApi("getIotOrder", "/iot/order/query", function(data){
    var sendData = dataToSendData(data, {
        strKey : ["uid","user_id","app_id","start_time","end_time"],
        numKey : ["state", "page_size","page_no"],
        numArrayKey : []
    });
    sendData.app_ids = data.app_ids
    return sendData
})

createApi("addAppDetail","/user/app/detail/add", function(data){
    var sendData = dataToSendData(data, {
        strKey : ["app_id","app_packet"],
        numKey : ["rela_id", "state", "client_type"],
        numArrayKey : []
    });

    return sendData
})
createApi("editAppDetail","/user/app/detail/set", function(data){
    var sendData = dataToSendData(data, {
        strKey : ["app_id","app_packet"],
        numKey : ["id", "state", "client_type"],
        numArrayKey : []
    });
    return sendData
})
createApi("queryAppBase","/user/app/query", function(data){
    data.name = data.app_name
    var sendData = dataToSendData(data, {
        strKey : ["app_id","name"],
        numKey : ["page_size","page_no", "comp_id"],
        numArrayKey : []
    });
    return sendData
})
createApi("queryAppDetail","/user/app/detail/query", function(data){
    var sendData = dataToSendData(data, {
        strKey : [],
        numKey : ["id"],
        numArrayKey : []
    });
    return sendData
})

createApi("queryIotReg","/iot/uid/reg/query", function(data){
    var sendData = dataToSendData(data, {
        strKey : ["uid", "mac"],
        numKey : ["state", "page_size", "page_no"],
        numArrayKey : []
    });
    return sendData
})
createApi("getClientStatis","/ana/user/client/statistics", function(data){
    var sendData = dataToSendData(data, {
        strKey : ["app_id", "start_time", "end_time", "app_name"],
        numKey : [],
        numArrayKey : []
    });
    return sendData
})
createApi("getTodayClientStatis","/ana/user/client/statistics/today", function(data){
    var sendData = dataToSendData(data, {
        strKey : ["app_id", "order_by", "app_name"],
        numKey : ["page_size","page_no"],
        numArrayKey : []
    });
    return sendData
})

createApi("unbindCS","/mall/order/bind/set", function(data){
    var sendData = dataToSendData(data, {
        strKey : [ "category_id", "order_id", "user_id", "uid"],
        numKey : ["type","channel"],
        numArrayKey : []
    });
    return sendData
})

createApi("getTotalClientInfo","/ana/user/client/statistics/total", function(data){
    var sendData = dataToSendData(data, {
        strKey : ["app_id", "app_name"],
        numKey : [],
        numArrayKey : []
    });
    return sendData
})

createApi("addInfoEmail","/discovery/srv/email/add", function(data){
    var sendData = dataToSendData(data, {
        strKey : ["sys", "link"],
        numKey : ["state"],
        numArrayKey : []
    });
    return sendData
})
createApi("editInfoEmail","/discovery/srv/email/edit", function(data){
    var sendData = dataToSendData(data, {
        strKey : ["sys", "link"],
        numKey : ["id", "state"],
        numArrayKey : []
    });
    return sendData
})
createApi("delInfoEmail","/discovery/srv/email/del", function(data){
    var sendData = dataToSendData(data, {
        strKey : [],
        numKey : ["id"],
        numArrayKey : []
    });
    return sendData
})
createApi("queryInfoEmail","/discovery/srv/email/query", function(data){
    var sendData = dataToSendData(data, {
        strKey : [],
        numKey : ["page_size", "page_no"],
        numArrayKey : []
    });
    return sendData
})
createApi("queryDevRegAna","/ana/iot/dev", function(data){
    var sendData = dataToSendData(data, {
        strKey : ["start_time", "end_time", "sys"],
        numKey : [],
        numArrayKey : []
    });
    return sendData
})
createApi("getSysList","/user/sys/query", function(data){
    var sendData = dataToSendData(data, {
        strKey : ["sys", "net_addr", "region", "zone"],
        numKey : ["state", "type", "page_no", "page_size"],
        numArrayKey : []
    });
    return sendData
})
createApi("getProduct","/user/product/get", function(data){
    var sendData = dataToSendData(data, {
        strKey : ["name", "pk"],
        numKey : ["page_no", "page_size"],
        numArrayKey : []
    });
    return sendData
})
createApi("addProduct","/user/product/add", function(data){
    var sendData = dataToSendData(data, {
        strKey : ["name", "img", "remark"],
        numKey : ["comp_id", "category", "node_type"],
        numArrayKey : []
    });
    return sendData
})
createApi("editProduct","/user/product/set", function(data){
    var sendData = dataToSendData(data, {
        strKey : ["name", "img", "remark"],
        numKey : ["id", "comp_id", "category", "node_type"],
        numArrayKey : []
    });
    return sendData
})
createApi("delProduct","/user/product/del", function(data){
    var sendData = dataToSendData(data, {
        strKey : ["name", "img", "remark"],
        numKey : ["id", "comp_id", "category", "node_type"],
        numArrayKey : []
    });
    return sendData
})
createApi("getProductVer","/ota/product/ver/get", function(data){
    var sendData = dataToSendData(data, {
        strKey : ["pk", "oem"],
        numKey : [],
        numArrayKey : []
    });
    return sendData
})
createApi("getSerZoneBind","/discovery/srv/zone/get", function(data){
    var sendData = dataToSendData(data, {
        strKey : ["sys"],
        numKey : ["page_no", "page_size"],
        numArrayKey : []
    });
    return sendData
})
createApi("addSerZoneBind","/discovery/srv/zone/add", function(data){
    var sendData = dataToSendData(data, {
        strKey : ["sys", "src_area", "dest_area", "remark"],
        numKey : ["src_type", "dest_type", "state"],
        numArrayKey : []
    });
    return sendData
})
createApi("editSerZoneBind","/discovery/srv/zone/set", function(data){
    var sendData = dataToSendData(data, {
        strKey : ["sys", "src_area", "dest_area", "remark"],
        numKey : ["src_type", "dest_type", "state","id"],
        numArrayKey : []
    });
    return sendData
})
createApi("delSerZoneBind","/discovery/srv/zone/del", function(data){
    var sendData = dataToSendData(data, {
        strKey : ["src_area"],
        numKey : ["src_type","id"],
        numArrayKey : []
    });
    return sendData
})
createApi("getRoleDetail","/user/role/info", function(data){
    var sendData = dataToSendData(data, {
        strKey : [],
        numKey : ["id"],
        numArrayKey : []
    });
    return sendData
})
createApi("getRoleList","/user/role/rela", function(data){
    var sendData = dataToSendData(data, {
        strKey : [],
        numKey : [],
        numArrayKey : []
    });
    return sendData
})

// 信息-平台-添加
createApi("getProductType", "/user/product/category/get" ,function(data){
    return dataToSendData(data, {
        strKey : [],
        numKey : ["parent_id"],
        numArrayKey : []
    });
})
createApi("getFeedbackList", "/user/feedback/query" ,function(data){
    return dataToSendData(data, {
        strKey : ["user_id", "app_id"],
        numKey : ["page_size","page_no"],
        numArrayKey : []
    });
})
createApi("getFeedbackDetail", "/user/feedback/detail/query" ,function(data){
    return dataToSendData(data, {
        strKey : [],
        numKey : ["title_id"],
        numArrayKey : []
    });
})
createApi("addNewFeedback", "/user/feedback/add" ,function(data){
    return dataToSendData(data, {
        strKey : ["app_id", "user_id", "uid", "contact", "title", "content"],
        numKey : [],
        numArrayKey : []
    });
})
createApi("modifyFeedback", "/user/feedback/modify" ,function(data){
    return dataToSendData(data, {
        strKey : ["app_id", "user_id", "uid", "contact", "title"],
        numKey : ["id", "state"],
        numArrayKey : []
    });
})
createApi("addNewFeedbackMsg", "/user/feedback/detail/add" ,function(data){
    return dataToSendData(data, {
        strKey : ["user_id", "content"],
        numKey : ["title_id", "type"],
        numArrayKey : []
    });
})
createApi("getDeviceState", "/iot/uid/state/get" ,function(data){
    return dataToSendData(data, {
        strKey : ["sys", "srv_ip", "uid"],
        numKey : ["page_no", "page_size"],
        numArrayKey : []
    });
})





if(serviceMode === "test"){
    window.$api = api
    window.$postData = postData;
}
export default api;
