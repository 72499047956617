<template>
  <div class="full-page balck-top login-page" :class="{ 'nav-open': $sidebar.showSidebar }">
    <notifications></notifications>
    <md-toolbar md-elevation="0" class="md-transparent md-toolbar-absolute">
      <!-- <div
        class="md-collapse"
        :class="{ 'off-canvas-sidebar': responsive }"
      > -->
      <md-list>
        <md-list-item class="black" href="/">
          <img src="/img/img/umeyelogo1.png" width="200" style="margin-left: -5px;">
        </md-list-item>
        <md-list-item class="black worktable" @click="gohome" v-if="$router.history.current.path != '/login'">
          <div style="">
            <md-icon style=' display: inline-block; color: rgb(153, 153, 153) !important; height: 22px; text-align: left; margin-right: 10px;'>home</md-icon>工作台
          </div>
          <!-- <img src="/img/img/umeyelogo1.png" width="200" style="margin-left: -5px;"> -->
        </md-list-item>
      </md-list>
    </md-toolbar>
    <div v-if="$router.history.current.path != '/login'" style="position: absolute;z-index: 10;right: 10px;">
      <a class="md-list-item-router md-list-item-container md-button-clean dropdown" style="overflow: visible;">
        <div class="md-list-item-content worktable_phone">
          <md-button
            @click="gohome"
            slot="title"
            class="md-button md-just-icon md-simple"
            data-toggle="dropdown"
          >
            <md-icon style="color: #000;">home</md-icon>
          </md-button>
        </div>
        <div class="md-list-item-content">
          <drop-down direction="down">
            <md-button
              slot="title"
              class="md-button md-just-icon md-simple"
              data-toggle="dropdown"
            >
              <md-icon style="color: #000;">person</md-icon>
              <!-- <p class="hidden-lg hidden-md">{{$I18n("language")}}</p> -->
            </md-button>
            <ul class="dropdown-menu dropdown-menu-right">
              <li><a href="#" @click="gobackLogin()">返回登录</a></li>
              <!-- <li><a href="#" @click="changeLanguage('en')">English</a></li> -->
            </ul>
          </drop-down>
        </div>
      </a>
    </div>
    <!-- 中间 + 底部 -->
    <div v-if="$router.history.current.path == '/login'" class="wrapper wrapper-full-page" @click="toggleSidebarPage">
      <div
        class="page-header header-filter"
        :class="setPageClass"
        filter-color="black"
        :style="setBgImage"
      > 
        <!-- 中间卡片 -->
        <div class="container md-offset">
          <zoom-center-transition
            :duration="pageTransitionDuration"
            mode="out-in"
          >
            <router-view @sucmsg="successMsg" @errmsg="errorMsg"></router-view>
          </zoom-center-transition>
        </div>
        <!-- 底部内容 -->
        <footer class="footer">
          <div class="copyright text-center foot-code">
            Copyright @ {{ new Date().getFullYear() }} All Right Reserved  广州市科缔智能科技有限公司    经营许可证：粤ICP备19147545号
          </div>
        </footer>
      </div>
    </div>
    <div v-else class="main-box">
      <router-view @sucmsg="successMsg" @errmsg="errorMsg"></router-view>
      <!-- 底部内容 -->
      <footer style="position: absolute; bottom: 0; right: 0;" class="footer">
        <div class="copyright text-center foot-code" >
          Copyright @ {{ new Date().getFullYear() }} All Right Reserved  广州市科缔智能科技有限公司    经营许可证：粤ICP备19147545号
        </div>
      </footer>
    </div>
    <!--弹窗 -->
    <modal v-if="smallAlertModal" @close="smallAlertModalHide">
      <template slot="header">
        <md-button class="md-simple md-just-icon md-round modal-default-button" @click="smallAlertModalHide">
          <md-icon>clear</md-icon>
        </md-button>
      </template>

      <template slot="header">
        语言选择
      </template>
      <template slot="body">
        <md-button class="md-primary language_button" @click="changeLanguage('zh')">中文</md-button>
        <md-button class="md-info language_button" @click="changeLanguage('en')">English</md-button>
        <!-- <md-button class="md-success language_button">法语</md-button> -->
      </template>
    </modal>
  </div>
</template>
<style scoped>
</style>
<script>
import { ZoomCenterTransition } from "vue2-transitions";
import { Modal } from "@/components";
export default {
  components: {
    Modal,
    ZoomCenterTransition,
  },
  props: {
    backgroundColor: {
      type: String,
      default: "black",
    },
  },
  inject: {
    autoClose: {
      default: true,
    },
  },
  watch:{
    '$router.history.current.query':{
      handler(){
        return 
      },
      immediate: true,
      deep: true,
    },
  },
  data() {
    return {
      host: window.location.host,
      smallAlertModal: false,
      responsive: false,
      showMenu: false,
      menuTransitionDuration: 250,
      pageTransitionDuration: 300,
      year: new Date().getFullYear(),
    };
  },
  created(){
  },
  computed: {
    setBgImage() {
      return {
        background: `#f8f8f8`,
      };
    },
    setPageClass() {
      return `${this.$route.name}-page`.toLowerCase();
    },
  },
  methods: {
    gohome(){
      if(this.$router.history.current.path != '/home'){
        this.$router.push('/home')
      }
    },
    successMsg(msg, time) {
      this.$notify({
        message        : msg,
        icon           : 'check',
        horizontalAlign: "center",
        verticalAlign  : "top",
        type           : "success",
        timeout        : !isNaN(time) ?time :2000,
      });
    },
    errorMsg: function(msg){
      this.$notify({
        message        : msg,
        icon           : 'error',
        horizontalAlign: "center",
        verticalAlign  : "top",
        type           : "danger",
        timeout        : 2000,
      });
    },
    gobackLogin(){
      localStorage.token  = "";
      localStorage.umh_ip = "";
      localStorage.login  = "";
      this.$router.push("/login")
    },
    smallAlertModalHide(){
      this.$data.smallAlertModal = false
    },
    changeLanguage(lang){
      if (lang === "zh"){
        lang = ""
      }
      this.$router.push({query: { lang }})
      this.$router.go(0)
    },


    toggleSidebarPage() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    toggleNavbar() {
      document.body.classList.toggle("nav-open");
      this.showMenu = !this.showMenu;
    },
    closeMenu() {
      document.body.classList.remove("nav-open");
      this.showMenu = false;
    },
    onResponsiveInverted() {
      if (window.innerWidth < 991) {
        this.responsive = true;
      } else {
        this.responsive = false;
      }
    },
  },
  mounted() {
    this.onResponsiveInverted();
    window.addEventListener("resize", this.onResponsiveInverted);
  },
  beforeDestroy() {
    this.closeMenu();
    window.removeEventListener("resize", this.onResponsiveInverted);
  },
  beforeRouteUpdate(to, from, next) {
    // Close the mobile menu first then transition to next page
    if (this.showMenu) {
      this.closeMenu();
      setTimeout(() => {
        next();
      }, this.menuTransitionDuration);
    } else {
      next();
    }
  },
};
</script>
<style lang="scss" scoped>
  .foot-code{
    color: rgba(73, 80, 87, 0.565) !important; 
    text-align: right; 
    width: 100%;
    padding-right: 30px;
  }
  .main-box{
    position: relative; 
    z-index:9;
    padding-top: 100px; 
    padding-left: 150px; 
    padding-right: 150px; 
    padding-bottom: 84px;
    min-height: 100vh;
    box-sizing: border-box;
  }
  .md-list-item.black{
    width: 225px;
  }
  .worktable_phone{
    display:none;
  }
  .worktable{
    & div{
      margin-top: 5px;
      border: 2px solid #BBBBBB;
      line-height: 44.5px; 
      text-align: center; 
      font-size: 22px; 
      color: #999999;
      width: 150px; 
      border-radius: 4px;
    }
  }
  @media screen and (max-width : 900px) {
    .foot-code{
      font-size: 12px;
    }
    .main-box{
      padding-left: 20px;
      padding-right: 20px; 
    }
    .md-transparent.md-toolbar-absolute{
      padding-left: 0 !important;
      padding-right:0 !important;
    }
    .worktable{
      display: none;
    }
    .worktable_phone, .md-list-item-content{
      display: inline-block;
    }
    .md-list-item-content{
      padding: 4px 0;
    }
    .md-list-item-router md-list-item-container md-button-clean dropdown{
      display: flex;
    }
  }
/* 本地样式 */
  .language_button {
    margin-right: 12px;
    margin-left: 12px;
  }
.md-toolbar-absolute{
  z-index : 10 !important;
}
/* .md-transparent.md-toolbar-absolute{
  background: #000 !important;
} */
.header-filter::before{
  background : none !important;  
}
$scaleSize: 0.1;
$zoomOutStart: 0.7;
$zoomOutEnd: 0.46;
@keyframes zoomIn8 {
  from {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
  100% {
    opacity: 1;
  }
}
.wrapper-full-page .zoomIn {
  animation-name: zoomIn8;
}
@keyframes zoomOut8 {
  from {
    opacity: 1;
    transform: scale3d($zoomOutStart, $zoomOutStart, $zoomOutStart);
  }
  to {
    opacity: 0;
    transform: scale3d($zoomOutEnd, $zoomOutEnd, $zoomOutEnd);
  }
}
.wrapper-full-page .zoomOut {
  animation-name: zoomOut8;
}
</style>
