<template>
  <div>
    <Menu></Menu>
    <div class="main-box ">
      <div class="earth-row">
        <div>
          <h1>云服务</h1>
          <p>基于科缔云的P2P云穿透技术，以视频物联云为主，面向硬件厂商及其合作伙伴构建云服务平台，实现硬件设备自主连网，通过手机可全球范围内控制硬件设备。通过后台数据统计服务，厂商可查看云产品相关运营情况</p>
          <div style="height: 48px; "></div>
        </div>
        <div class="earth-row-img">
          <img src="/img/img/index.png">
        </div>
      </div>
    </div>
    <div class="introduce" id="syno">
      <div class="int-title-box">
        <h2 class="int-title">平台简介</h2>
        <!-- <p class="int-sub-title">
          深耕行业多年，我们已发展为全球领先的 IoT 音视频平台。专业为您提供完善的产品服务
        </p> -->
      </div>
      <div class="int-flex-box">
        <div class="int-flex-box-item no-move no-border full-box">
          <div>
            <div class="int-flex-box-half img">
              <img src="/img/img/233796532506447872.png">
            </div>
            <div class="int-flex-box-half">
              <h3 class="int-flex-box-item-title">什么是科缔云</h3>
              <div class="int-flex-box-item-info">
                <p>
                  科缔云技术，意寓“你是我的眼”的意思，是一个灵活、可扩展的物联网平台。设备生产商、方案集成商、芯片提供商和电子产品OEM制造商可通过该平台快速、轻松地部署自身的联网设备生态系统。
                </p>
                <p>
                  科缔云以视频物联云服务为主，逐步深入智能家居、车联网等多个物联领域，依托在国内外十来个国家部署的云端服务器，实现用户在任何网络、时间、地点，都能连接和分享物联设备，免费使用安全、稳定、便捷的科缔云服务！
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="int-flex-box-item no-move no-border full-box">
          <div>
            <div class="int-flex-box-half">
              <!-- <h3 class="int-flex-box-item-title">平台简介</h3> -->
              <div class="int-flex-box-item-info">
                <p>
                  科缔云提供创新的解决方案设计时考虑未来，在快速发展的物联网环境中顺应挑战。科缔云将不同的设备整合于一个平台，利于各种联网产品的使用共性实现统一连接通讯。我们不做设备，却兼容任何设备，无疑是芯片商、方案商和生产商的最佳之选。
                </p>
                <p>
                  凭借多年来与各大运营商(移动和联通)在视频监控平台的建设和运营的丰富经验，以及在软硬件解码和QoS的全面突破，彻底革新了用户的云监控体验，科缔云在视频领域的高投入、高带宽和大数据的物联云能力的卓越表现，让越来越多种类和数量的终端踊跃接入科缔云服务平台，让普通的网络终端设备成为真正的云终端，让各大厂商成为互联网设备厂商，最终使广大消费者受益
                </p>
              </div>
            </div>
            <div class="int-flex-box-half img">
              <img src="/img/img/233796532506447872.png">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="introduce" id="advantage">
      <div class="int-title-box" id="">
        <h2 class="int-title">平台优势</h2>
      </div>

      <div class="int-flex-box advantage">
        <div class="int-flex-box-item">
          <div>
            <div class="int-flex-box-span">
              <h3 class="int-flex-box-item-title">超高穿透率，百分百连接</h3>
              <p class="int-flex-box-item-info">运营多年，拥有市场最成熟的P2P穿透技术，市场上大部分网络类型都能穿透，结合转发技术，可实现100%的连接成功率</p>
            </div>
            <div class="int-flex-box-img">
              <img src="/img/img/001.png">
            </div>
          </div>
        </div>
        <div class="int-flex-box-item">
          <div>
            <div class="int-flex-box-span">
              <h3 class="int-flex-box-item-title">强大的服务器集群</h3>
              <p class="int-flex-box-item-info">投资巨款搭建全球服务器，与各大云服务器商合作（亚马逊、阿里云、腾讯云等），保证服务高速稳定。</p>
            </div>
            <div class="int-flex-box-img">
              <img src="/img/img/001.png">
            </div>
          </div>
        </div>
        <div class="int-flex-box-item">
          <div>
            <div class="int-flex-box-span">
              <h3 class="int-flex-box-item-title">完善的后台数据展示</h3>
              <h3 class="int-flex-box-item-info">结合先进的数据分析技术，生成各类数据报表，实时掌控分析用户使用情况，帮助厂商提高服务效率。</h3>
            </div>
            <div class="int-flex-box-img">
              <img src="/img/img/001.png">
            </div>
          </div>
        </div>
        <div class="int-flex-box-item">
          <div>
            <div class="int-flex-box-span">
              <h3 class="int-flex-box-item-title">高可扩展性</h3>
              <h3 class="int-flex-box-item-info">具备成本效益，功能模块化，拥有强大的云端模块和可扩展性架构，可迅速推出新服务</h3>
            </div>
            <div class="int-flex-box-img">
              <img src="/img/img/001.png">
            </div>
          </div>
        </div>
        <div class="int-flex-box-item">
          <div>
            <div class="int-flex-box-span">
              <h3 class="int-flex-box-item-title">性能优越，安全可靠</h3>
              <h3 class="int-flex-box-item-info">提供优异的影音压缩技术及高效的传输性能，连接技术改进和网络优化，保护最终用户隐私，支持各种类型文件、加快连接速度，减少数据带来的成本，</h3>
            </div>
            <div class="int-flex-box-img">
              <img src="/img/img/001.png">
            </div>
          </div>
        </div>
        <div class="int-flex-box-item">
          <div>
            <div class="int-flex-box-span">
              <h3 class="int-flex-box-item-title">服务增值，实现创收</h3>
              <h3 class="int-flex-box-item-info">服务框架能够帮助企业快速为客户创造价值，提供增值服务，包括：云存储、个性化、功能授权等，实现创收</h3>
            </div>
            <div class="int-flex-box-img">
              <img src="/img/img/001.png">
            </div>
          </div>
        </div>
        <!-- <div class="int-flex-box-item">
          <div>
            <div class="int-flex-box-span">
              <h3 class="int-flex-box-item-title">具备成本效益，拥有强大的云端模块和可扩展性架构，迅速推出新服务。</h3>
            </div>
            <div class="int-flex-box-img">
              <img src="/img/img/001.png">
            </div>
          </div>
        </div>
        <div class="int-flex-box-item">
          <div>
            <div class="int-flex-box-span">
              <h3 class="int-flex-box-item-title">服务框架能够帮助企业快速为客户创造价值，包括：云存储、整合个人云服务如阿里云，可管理用户信息。</h3>
            </div>
            <div class="int-flex-box-img">
              <img src="/img/img/001.png">
            </div>
          </div>
        </div>
        <div class="int-flex-box-item">
          <div>
            <div class="int-flex-box-span">
              <h3 class="int-flex-box-item-title">连接技术改进网络优化，保护最终用户隐私，支持各种类型文件、加快连接速度，减少数据带来的成本。</h3>
            </div>
            <div class="int-flex-box-img">
              <img src="/img/img/001.png">
            </div>
          </div>
        </div>
        <div class="int-flex-box-item">
          <div>
            <div class="int-flex-box-span">
              <h3 class="int-flex-box-item-title">提供优异的影音压缩技术及高效的传输性能。</h3>
            </div>
            <div class="int-flex-box-img">
              <img src="/img/img/001.png">
            </div>
          </div>
        </div>
        <div class="int-flex-box-item">
          <div>
            <div class="int-flex-box-span">
              <h3 class="int-flex-box-item-title">通过一个简单的UMID，为用户获得即插即用的设备连接设置，快速连接。</h3>
            </div>
            <div class="int-flex-box-img">
              <img src="/img/img/001.png">
            </div>
          </div>
        </div> -->
      </div>
    </div>
    <div class="introduce" id="cmposition">
      <div class="int-title-box">
        <h2 class="int-title">平台组成</h2>
        <p class="int-sub-title">
          <!-- 科缔云平台是一款专业的物联网云端服务平台。它由云服务端(Cloud)、设备连接端(Connet) 和 客户端(Application)组成。因此，科缔团队目前为企业和消费者提供的解决方案应用在智能家居、智能监控、智能报警、家庭安全等环境。 -->
        </p>
      </div>
      <div class="int-flex-box">
        <div class="int-flex-box-item full-box no-move">
          <div class="int-flex-box-list-item">
            <div class="list-item-box">
              <div @click="selected = 0" :class="{'selected' : selected === 0}" class="list-item-list">平台组成</div>
              <div @click="selected = 1" :class="{'selected' : selected === 1}" class="list-item-list">服务端</div>
              <div @click="selected = 2" :class="{'selected' : selected === 2}" class="list-item-list">连接端</div>
              <div @click="selected = 3" :class="{'selected' : selected === 3}" class="list-item-list">客户端</div>
            </div>
            <div class="info-item-box">
              <div v-if="selected === 0">
                科缔云平台是一款专业的物联网云端服务平台。它由云服务端(Cloud)、设备连接端(Connet) 和 客户端(Application)组成。因此，科缔团队目前为企业和消费者提供的解决方案应用在智能家居、智能监控、智能报警、家庭安全等环境。
                <div :style="{'text-align':'center'}"><img src="/img/img/05213454rj4n.jpg"></div>
              </div>
              <div v-if="selected === 1">
                <p>
                  服务端平台为您提供最好的设备和服务管理来维护您与客户的关系，最终能让您增加收入。它管理的所有设备、中心节点、应用程序连接到科缔云平台，提供先进的数据分析、远程访问、身份验证、已经许可的访问和通用消息。通过该平台可以实现警报、流媒体传播、时间序列存储数据分析、数据服务、用户的帐户管理开发、新功能交付和服务的动作，进一步加强客户关系。
                </p>
                <p>
                  拥有强大的模块化和可扩展的架构，能够快速推出新服务。不管是通过Wi-Fi或3G，科缔云端都可以处理数以百万的连接和大量的数据，并将它们应用在单一的设备里或家里的应用程序。
                </p>
                <p>
                  竞争优势
                </p>
                <p>
                  实现设备和客户端的通信，由中心管理平台来按设备所在区域智能选择对应功能服务器，通过算法实现负载均衡。平台采用分布式、机群式服务为用户提供无间断平台式服务。统一接口至设备与访问端，由中心管理平台来按设备所在区域智能选择对应功能服务器。且负载均衡主要通过云计算与主服务器分配来实现。至今为止科缔云服务器在全球的分布，其服务器分布能够覆盖世界绝大部分角落。
                </p>
              </div>
              <div v-if="selected === 2">
                <p>
                  科缔云连接端就如同平台媒介，负责将硬件设备和云端服务连接，其功能可以让设备如：路由器、机顶盒、网络摄像机甚至只能电视成为智能家庭中心与其他产品互联。它把底层所需要的关键协议和标准整合在一起，创建一个丰富的家庭安全连接环境，包括Z-Wave、ZigBee、Wi-Fi、蓝牙以及专有协议。
                </p>
                <p>
                  竞争优势
                </p>
                <p>
                  ※  科缔云在产品进入市场的价格、时间上提供了相对应的灵活性，支持市面上各大主流SOC芯片
                </p>
                <p>
                  ※  科缔云拥有API编码和解码能力、专业的影音压缩技术，使得流媒体更快速、更强大、更能整合智能家居的应用。
                </p>
                <p>
                  ※  科缔云通过UMID极大地简化了连接的设置，用户只需要扫描二维码，在App中输入密码，即可建立手机与设备的连接。
                </p>
              </div>
              <div v-if="selected === 3">
                <p>
                  科缔云提供了跨平台的App，拥有一致的用户体验和丰富的功能特性，可以定制和传递品牌信息。
                </p>
                <p>
                  我们努力为市场提供框架服务，让产品更快地推向市场。您可以用App来处理更多设备之间的添加和删除，并对设备进行布局掌控，更具体地说，利用设备的自动化连接，灵活地控制所有指令，让设备之间的交互没有障碍。
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="introduce" id="industry">
      <div class="int-title-box">
        <h2 class="int-title">应用行业</h2>
        <!-- <p class="int-sub-title">
          深耕行业多年，我们已发展为全球领先的 IoT 音视频平台。专业为您提供完善的产品服务
        </p> -->
      </div>
      <div class="int-flex-box">
        <div class="int-flex-box-item no-move no-border full-box">
          <div>
            <div class="int-flex-box-half img">
              <img src="/img/img/233796532506447872.png">
            </div>
            <div class="int-flex-box-half">
              <h3 class="int-flex-box-item-title">P2P穿透技术</h3>
              <div class="int-flex-box-item-info">
                <p>
                  随着Internet飞速发展，IPv4地址日益不足，不得不使用NAT技术来提高地址使用率，从逻辑意义上扩展地址空间。P2P技术的出现也是Internet发展的结果，其优点在于任意两节点间可以随意通信，摆脱了客户端对服务器的依赖，用了P2P技术，就可以实现无需路由的端口映射实现内网与内网或内网于外网直接的通讯
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="int-flex-box-item no-move no-border full-box">
          <div>
            <div class="int-flex-box-half">
              <h3 class="int-flex-box-item-title">点对点连接</h3>
              <div class="int-flex-box-item-info">
                <p>
                  利用科缔云的P2P技术，设备接上网络能穿透内网主动寻找服务器，与互联网连接，实现网络通讯。只需利用一个唯一的识别码（我们称为UID），我们便能轻松连接硬件设备。设备先通过服务器打通网络通讯，客户端从服务器获取与设备的连接信息，实现客户端与设备的点对点连接
                </p>
              </div>
            </div>
            <div class="int-flex-box-half img">
              <img src="/img/img/233796532506447872.png">
            </div>
          </div>
        </div>
        <div class="int-flex-box-item no-move no-border full-box">
          <div>
            <div class="int-flex-box-half img">
              <img src="/img/img/233796532506447872.png">
            </div>
            <div class="int-flex-box-half">
              <h3 class="int-flex-box-item-title">可用行业</h3>
              <div class="int-flex-box-item-info">
                <p>芯片方案集成商</p>
                <p>设备生产商（网络通讯设备商：摄像机、硬盘录像机、机顶盒、传感器等等）</p>
                <p>安全监控行业</p>
                <p>智能家居</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Foot></Foot>
  </div>
</template>

<script>
import Menu from "@/pages/Dashboard/Menu.vue";
import Foot from "@/pages/Dashboard/Foot.vue";
export default {
  components:{
    Menu : Menu,
    Foot : Foot,
  },
  data() {
    return {
      selected: 0,
    };
  },
  mounted() {
  },
  methods : {
  }
};
</script>
<style scoped>
  body, h2, h3, h4, h5, h6, .h2, .h3, .h4, a, p{
    font-weight: 400 !important;
  }
  div.introduce{
    margin: 72px auto;
    /*margin: 72px 0;*/
    padding: 0 72px;
    max-width: 1200px;
  }
  .advantage .int-flex-box-img{
    max-width: 200px;
    min-width: 200px;
  }
  .advantage .int-flex-box-img img {
    width: 200px;
  }
  .info-item-box p{
    margin: 0 0 20px 0;
  }
  .int-flex-box-list-item{
    align-items: stretch !important;
    flex-direction: row !important;
  }
  .info-item-box img{
    margin: 42px 0;
  }
  .info-item-box{
    box-sizing: border-box;
    padding: 58px 36px 48px;
    background-color: #ffff;
    flex-basis: 60%;
    flex-grow: 1;
    flex-shrink: 0;
    /*max-width: ;*/
  }
  .list-item-box{
    box-sizing: border-box;
    padding: 58px 0;
    background-color: #f6fafe;
    flex-basis: 40%;
    flex-grow: 0;
    flex-shrink: 0;
    max-width: 360px;
    min-height: 600px;
  }
  .list-item-box .list-item-list {
    cursor: pointer;
    padding: 0 54px;
    line-height: 48px;
    font-size: 16px;
    width: 100%;
    box-sizing: border-box;
  }
  .list-item-box .list-item-list.selected {
    background: #025dff;
    color: #ffffff;
  }
  .int-flex-box-half .int-flex-box-item-title{
    font-size: 36px;
    line-height: 46px;
    margin-bottom: 36px;
    height: unset;
  }
  .int-flex-box-half.img{
    text-align: center;
  }
  img{
    max-width: 100%;
  }
  .int-flex-box-half{
    box-sizing: border-box;
    padding: 0 36px;
    flex-basis: 50%;
    flex-grow: 0;
    flex-shrink: 0;
    max-width: 50%;
  }
  .int-flex-box-img img{
    width: 300px;
  }
  .int-flex-box-item-info p{
    margin: 4px 0;
  }
  .int-flex-box-item-info{
    font-size: 16px;
    font-family: "PingFang SC";
    line-height: 26px;
    color: rgb(96,98,102);
    margin: 0;
  }
  .int-flex-box-item-title{
    font-size: 20px;
    /*height: 30px;*/
    margin: 0;
    margin-bottom: 12px;
  }
  .int-flex-box-img{
    max-width: 300px;
    min-width: 300px;
  }
  .int-flex-box-span{
    display: flex;
    flex: 1 1 auto;
    padding: 54px 16px 54px 40px;
    flex-direction: column;
  }
  .int-flex-box-item>div:hover{
    top: -10px;
    box-shadow: 0 -2px 10px 0 hsl(225deg 3% 46% / 3%), 0 14px 20px 0 hsl(225deg 3% 46% / 18%);
  }
  .int-flex-box-item.no-move>div:hover{
    top: 0;
    box-shadow: 0 4px 15px 0 rgb(37 60 126 / 12%);
  }
  .int-flex-box-item.no-border>div{
    border: 0 !important;
    box-shadow: none !important;
  }
  .int-flex-box-item>div{
    display: flex;
    background-color: rgb(255, 255, 255);
    min-height: 240px;
    height: 100%;
    margin: 0px auto;
    box-shadow: 0 4px 15px 0 rgb(37 60 126 / 12%);
    border-radius: 8px;
    position: relative;
    transition: top .3s, box-shadow .3s;
    top: 0;
    justify-content: center;
    align-items: center;
  }
  .int-flex-box-item.full-box{
    max-width: 100%;
    flex-basis: 100%;
  }
  .int-flex-box-item{
    padding: 12px;
    flex-basis: 50%;
    flex-grow: 0;
    flex-shrink: 0;
    max-width: 50%;
    box-sizing: border-box;
  }
  .int-flex-box{
    margin-left: -12px;
    margin-right: -12px;
    margin-top: -12px;
    margin-bottom: 12px;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    align-items: center;
  }
  .int-title-box{
    text-align: center;
    padding: 0 12px 60px;
  }
  .int-sub-title{
    margin-top:24px;
    color: rgb(96, 98, 102);
    font-size: 16px;
    line-height: 26px;
    height: 26px;
    margin-bottom: 0;
  }
  .int-title{
    line-height: 50px;
    height: 50px;
    margin: 0;
    font-size: 40px;
    font-weight: 700;
  }
  .earth-row{
    max-width: 1300px;
    display: flex;
    margin: 0 auto;
    height: 500px;
  }
  .earth-row img{
    width: 100%;
  }
  .earth-row p{
    color: #606266;
    line-height: 26px;
  }

  .earth-row>div{
    align-items:center;
    padding: 0 12px;
    width: 50%;
    align-self: center;
    box-sizing: border-box;
    /*padding-left: 12px;*/
    /*padding-right: 12px;*/
    flex-basis: 50%;
    flex-grow: 0;
    flex-shrink: 0;
    max-width: 50%;
  }
  .main-box p{
    margin: 32px 0 42px;
    font-size: 16px;
  }
  .main-box h1{
    line-height: 66px;
    font-size: 56px;
    margin: 0;
    font-weight: bold;
    color: #000;
    /*margin-top: 64px;*/
  }
  .main-box{
    box-shadow: inset 0 0 50px rgb(0 0 0 / 5%);
    box-sizing: border-box;
    height: 500px;
    width: 100%;
    /*padding: 0 72px;*/
    margin: 0 auto;
    background-color: #eef2f6;
    clear: both;
    background-repeat: no-repeat;
    background-size: cover;
    flex-wrap: wrap;
    font-family: PingFang-SC-Regular, "PingFang SC", "Helvetica Neue", Helvetica, Arial, "Microsoft Yahei", "Hiragino Sans GB", "Heiti SC", "WenQuanYi Micro Hei", sans-serif;
  }
  @media screen and (max-width: 1023px){
    div.introduce{
      padding: 0 32px;
    }
    .int-flex-box-half {
        box-sizing: border-box;
        flex-basis: 100%;
        max-width: 100%;
        padding: 0;
        flex-grow: 0;
        flex-shrink: 0;
    }
    .info-item-box{
      padding: 12px 36px;
    }
    .list-item-box{
      padding: 12px 0;
    }
    .init-title-box{
      height: 150px;
    }
    .main-box p {
      margin: 24px 0;
    }
    .main-box {
      padding: 0 12px;
      height: unset;
    }
    .earth-row{
      height: unset;
    }
    .earth-row>div{
      width: 100%;
      max-width: 100%;
      flex-basis: 100%;
      padding: 80px 0 36px;
    }
    .earth-row-img{
      display: none;
    }
    .main-box h1{
      font-weight: bold;
      color: #000;
      font-size: 36px;
    }
    .int-flex-box-item{
      width: 100%;
      max-width: 100%;
      flex-basis: 100%;
    }
    .int-flex-box-item>div{
      flex-direction: column;
      align-items: center;
    }
    .int-flex-box-span {
      padding: 48px 26px 0;
    }
  }
</style>