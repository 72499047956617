<template>
	<div style="width: 100%;">
		<div class="md-layout">
			<!-- 卡片 -->
			<div class="card-body">
				<div class="hover-card">
					<md-card>
						<!-- 卡片-主体 -->
						<md-card-content>
							<div style="text-align: center;">
								<img src="/img/svg/dev.svg" class="img-svg">
							</div>
							<div class="card-box">设备管理</div>
						</md-card-content>
					</md-card>
				</div>
			</div>
			<!-- 卡片 -->
			<div class="card-body" @click="$router.push('/info_cloud')">
				<div class="hover-card">
					<md-card>
						<!-- 卡片-主体 -->
						<md-card-content>
							<div style="text-align: center;">
								<img src="/img/svg/cloud.svg" class="img-svg">
							</div>
							<div class="card-box">
								云存储
							</div>
						</md-card-content>
					</md-card>
				</div>
			</div>
		</div>
	</div>
</template>
<!--  -->
<script>
// import axios from 'axios';
// import { LoginCard } from "@/components";
// axios.defaults.withCredentials = true //开启后服务器才能拿到cookie
// axios.defaults.crossDomain = true;//配置axios跨域
// axios.defaults.headers.post['Content-Type'] = 'application/json; charset=UTF-8'//配置默认请求头
export default {}
</script>
<style lang="scss" scoped>
	.hover-card{
		cursor: pointer;
	}
	.card-body{
		margin: 0 20px;
		height: 140px;
		width: 140px;
	}
	.card-box{
		line-height: 50px;
		text-align: center;
		font-weight: 600; 
		color: #909090; 
		font-size: 20px;
	}
	.img-svg{
		width: 60px;
	}
	@media screen and (max-width : 900px) {
		.img-svg{
			width: 40px;
		}
		.card-body{
			width: 100px;
			height:100px;
		}
		.card-box{
			font-size: 14px;
			line-height: 24px;
		}
	}
</style>