<template>
  <div>
    <Menu></Menu>
    <div class="main-box ">
      <div class="earth-row">
        <div>
          <h1>硬件接入</h1>
          <p>提供设备端sdk，已支持各大芯片厂商，欢迎接入</p>
          <p><b><a href="http://docs.kdzn.top">SDK下载</a></b></p>
          <div style="height: 48px; "></div>
        </div>
        <div class="earth-row-img">
          <img src="/img/img/index.png">
        </div>
      </div>
    </div>
    <div class="introduce" id="explain">
      <div class="int-title-box" id="advantage">
        <h2 class="int-title">接入说明</h2>
      </div>
      <div class="int-flex-box">
        <div class="int-flex-box-item no-move no-border full-box">
          <div>
            <div class="int-flex-box-half img">
              <img src="/img/img/233796532506447872.png">
            </div>
            <div class="int-flex-box-half">
              <h3 class="int-flex-box-item-title">接入简单，功能齐全</h3>
              <div class="int-flex-box-item-info">
                <p>
                  设备端SDK为厂商设备接入科缔云平台提供快捷开发的SDK包，该SDK包提供协议处理，客户端连接处理，上层应用程序不需要关心客户端连接及交互协议，只需将媒体流传入SDK即可。
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="int-flex-box-item no-move no-border full-box">
          <div>
            <div class="int-flex-box-half">
              <h3 class="int-flex-box-item-title">公版客户端，直接使用</h3>
              <div class="int-flex-box-item-info">
                <p>
                  设备端按该SDK开发相关程序，即可接入到科缔云平台，并能使用现有的公版观看设备视频。该接入方式免去了开发客户端的协议接入程序，客户端已完成开发，可直接使用，或者可按客户定制界面及功能。
                </p>
              </div>
            </div>
            <div class="int-flex-box-half img">
              <img src="/img/img/233796532506447872.png">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="introduce" id="function">
      <div class="int-title-box" id="advantage">
        <h2 class="int-title">核心功能</h2>
      </div>

      <div class="int-flex-box advantage">
        <div class="int-flex-box-item">
          <div>
            <div class="int-flex-box-span">
              <h3 class="int-flex-box-item-title">实时视频播放</h3>
              <p class="int-flex-box-item-info">视频流实时解码播放，低延时，稳定流畅</p>
            </div>
            <div class="int-flex-box-img">
              <img src="/img/img/001.png">
            </div>
          </div>
        </div>
        <div class="int-flex-box-item">
          <div>
            <div class="int-flex-box-span">
              <h3 class="int-flex-box-item-title">远程录像回放</h3>
              <p class="int-flex-box-item-info">随时随地回放存储在硬件设备上的视频</p>
            </div>
            <div class="int-flex-box-img">
              <img src="/img/img/001.png">
            </div>
          </div>
        </div>
        <div class="int-flex-box-item">
          <div>
            <div class="int-flex-box-span">
              <h3 class="int-flex-box-item-title">报警消息推送</h3>
              <h3 class="int-flex-box-item-info">消息云推送，及时获取设备的报警记录</h3>
            </div>
            <div class="int-flex-box-img">
              <img src="/img/img/001.png">
            </div>
          </div>
        </div>
        <div class="int-flex-box-item">
          <div>
            <div class="int-flex-box-span">
              <h3 class="int-flex-box-item-title">视频云存储</h3>
              <h3 class="int-flex-box-item-info">支持云端存储视频，视频不丢失，随时查看</h3>
            </div>
            <div class="int-flex-box-img">
              <img src="/img/img/001.png">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="introduce" id="setp">
      <div class="int-title-box">
        <h2 class="int-title">开发步骤</h2>
      </div>
      <div class="int-flex-box step">
        <div class="int-flex-box-item step-full-box">
          <div>
            <div class="int-flex-box-img">
              <img src="/img/img/001.png">
            </div>
            <div class="int-flex-box-span">
              <h3 class="int-flex-box-item-title">01、初始化环境</h3>
              <p class="int-flex-box-item-info">调用函数NPC_F_DPS_StartDevProNetServer初始化环境，该函数初始化相关资源，并启动监听端口。</p>
            </div>
          </div>
        </div>
        <div class="int-flex-box-item step-full-box">
          <div>
            <div class="int-flex-box-img">
              <img src="/img/img/001.png">
            </div>
            <div class="int-flex-box-span">
              <h3 class="int-flex-box-item-title">02、在回调函数中处理消息</h3>
              <p class="int-flex-box-item-info">第一步将传入回调函数，SDK在接收到客户端请求后，经处理，会通过回调该函数来通知上层需要处理的内容，如需要打开某个通道的实时预览，则在该回调函数中分析消息，并按消息处理相关工作。</p>
            </div>
          </div>
        </div>
        <div class="int-flex-box-item step-full-box">
          <div>
            <div class="int-flex-box-img">
              <img src="/img/img/001.png">
            </div>
            <div class="int-flex-box-span">
              <h3 class="int-flex-box-item-title">03、处理打开实时预览请求</h3>
              <p class="int-flex-box-item-info">当客户端需要打开某个通道的实时预览，则SDK会通过回调通知上层应用，则上层应用打开该通道的实时预览，该过程需要在无阻塞的情况下工作，需要应用处理完后立即返回，不影响底层线程处理客户端的消息。在该过程中，需要加锁处理，由于底层与上层都对相同内存操作，则需要加锁互斥工作。</p>
            </div>
          </div>
        </div>
        <div class="int-flex-box-item step-full-box">
          <div>
            <div class="int-flex-box-img">
              <img src="/img/img/001.png">
            </div>
            <div class="int-flex-box-span">
              <h3 class="int-flex-box-item-title">04、其它请求处理</h3>
              <p class="int-flex-box-item-info">处理云台控制，录像回放等消息。</p>
            </div>
          </div>
        </div>
        <div class="int-flex-box-item step-full-box">
          <div>
            <div class="int-flex-box-img">
              <img src="/img/img/001.png">
            </div>
            <div class="int-flex-box-span">
              <h3 class="int-flex-box-item-title">05、释放资源</h3>
              <p class="int-flex-box-item-info">当程序退出时，需要调用NPC_F_DPS_StopDevProNetServer函数释放相关资源。</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Foot></Foot>
  </div>
</template>

<script>
import Menu from "@/pages/Dashboard/Menu.vue";
import Foot from "@/pages/Dashboard/Foot.vue";
export default {
  components:{
    Menu : Menu,
    Foot : Foot,
  },
  data() {
    return {
      selected: 0,
    };
  },
  mounted() {
  },
  methods : {
  }
};
</script>
<style scoped>
  body, h2, h3, h4, h5, h6, .h2, .h3, .h4, a, p{
    font-weight: 400 !important;
  }
  a{
    text-decoration: none;
  }
  .int-flex-box-item.step-full-box .int-flex-box-img{
    background-color: #f9fafb;
    text-align: center;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
  .int-flex-box-item.step-full-box .int-flex-box-img img{
    max-height: 270px;
    width: auot;
  }
  .int-flex-box-item.step-full-box .int-flex-box-span h3,
  .int-flex-box-item.step-full-box .int-flex-box-span p{
    text-align: left;
  }
  .int-flex-box-item.step-full-box .int-flex-box-span{
    padding: 40px 32px 40px;
    text-align: left;
  }
  .int-flex-box-item.step-full-box{
    max-width: 100%;
    flex-basis: 100%;
  }
  .int-flex-box-item.step-full-box>div{
    align-items: flex-start;
  }
  
  .int-flex-box-item.step-full-box>div>div{
    width: 50%;
    flex-basis: 50%;
    box-sizing: border-box;
    flex-basis: 50%;
    max-width: 50%;
  }

  .int-flex-box.step{
    max-width: 1000px;
    margin: 0 auto;
  }
  div.introduce{
    margin: 72px auto;
    /*margin: 72px 0;*/
    padding: 0 72px;
    max-width: 1200px;
  }
  .advantage .int-flex-box-img{
    max-width: 200px;
    min-width: 200px;
  }
  .advantage .int-flex-box-img img {
    width: 200px;
  }
  .info-item-box p{
    margin: 0 0 20px 0;
  }
  .int-flex-box-list-item{
    align-items: stretch !important;
    flex-direction: row !important;
  }
  .info-item-box img{
    margin: 42px 0;
  }
  .info-item-box{
    box-sizing: border-box;
    padding: 58px 36px 48px;
    background-color: #ffff;
    flex-basis: 60%;
    flex-grow: 1;
    flex-shrink: 0;
    /*max-width: ;*/
  }
  .list-item-box{
    box-sizing: border-box;
    padding: 58px 0;
    background-color: #f6fafe;
    flex-basis: 40%;
    flex-grow: 0;
    flex-shrink: 0;
    max-width: 360px;
    min-height: 600px;
  }
  .list-item-box .list-item-list {
    cursor: pointer;
    padding: 0 54px;
    line-height: 48px;
    font-size: 16px;
    width: 100%;
    box-sizing: border-box;
  }
  .list-item-box .list-item-list.selected {
    background: #025dff;
    color: #ffffff;
  }
  .int-flex-box-half .int-flex-box-item-title{
    font-size: 36px;
    line-height: 46px;
    margin-bottom: 36px;
    height: unset;
  }
  .int-flex-box-half.img{
    text-align: center;
  }
  img{
    max-width: 100%;
  }
  .int-flex-box-half{
    box-sizing: border-box;
    padding: 0 36px;
    flex-basis: 50%;
    flex-grow: 0;
    flex-shrink: 0;
    max-width: 50%;
  }
  .int-flex-box-img img{
    width: 300px;
  }
  .int-flex-box-item-info p{
    margin: 4px 0;
  }
  .int-flex-box-item-info{
    font-size: 16px;
    font-family: "PingFang SC";
    line-height: 26px;
    color: rgb(96,98,102);
    margin: 0;
  }
  .int-flex-box-item-title{
    font-size: 20px;
    height: 30px;
    margin: 0;
    margin-bottom: 12px;
  }
  .int-flex-box-img{
    max-width: 300px;
    min-width: 300px;
  }
  .int-flex-box-span{
    display: flex;
    flex: 1 1 auto;
    padding: 54px 16px 54px 40px;
    flex-direction: column;
  }
  .int-flex-box-item>div:hover{
    top: -10px;
    box-shadow: 0 -2px 10px 0 hsl(225deg 3% 46% / 3%), 0 14px 20px 0 hsl(225deg 3% 46% / 18%);
  }
  .int-flex-box-item.no-move>div:hover{
    top: 0;
    box-shadow: 0 4px 15px 0 rgb(37 60 126 / 12%);
  }
  .int-flex-box-item.no-border>div{
    border: 0 !important;
    box-shadow: none !important;
  }
  .int-flex-box-item>div{
    display: flex;
    background-color: rgb(255, 255, 255);
    min-height: 240px;
    height: 100%;
    margin: 0px auto;
    box-shadow: 0 4px 15px 0 rgb(37 60 126 / 12%);
    border-radius: 8px;
    position: relative;
    transition: top .3s, box-shadow .3s;
    top: 0;
    justify-content: center;
    align-items: center;
  }
  .int-flex-box-item.full-box{
    max-width: 100%;
    flex-basis: 100%;
  }
  .int-flex-box-item{
    padding: 12px;
    flex-basis: 50%;
    flex-grow: 0;
    flex-shrink: 0;
    max-width: 50%;
    box-sizing: border-box;
  }
  .int-flex-box-item.full-box{
    flex-basis: 100%;
    max-width: 100%;
  }
  .int-flex-box{
    margin-left: -12px;
    margin-right: -12px;
    margin-top: -12px;
    margin-bottom: 12px;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    align-items: center;
  }
  .int-title-box{
    text-align: center;
    padding: 0 12px 60px;
  }
  .int-sub-title{
    margin-top:24px;
    color: rgb(96, 98, 102);
    font-size: 16px;
    line-height: 26px;
    height: 26px;
    margin-bottom: 0;
  }
  .int-title{
    line-height: 50px;
    height: 50px;
    margin: 0;
    font-size: 40px;
    font-weight: 700;
  }
  .earth-row{
    max-width: 1300px;
    display: flex;
    margin: 0 auto;
    height: 500px;
  }
  .earth-row img{
    width: 100%;
  }
  .earth-row p{
    color: #606266;
    line-height: 26px;
  }

  .earth-row>div{
    align-items:center;
    padding: 0 12px;
    width: 50%;
    align-self: center;
    box-sizing: border-box;
    /*padding-left: 12px;*/
    /*padding-right: 12px;*/
    flex-basis: 50%;
    flex-grow: 0;
    flex-shrink: 0;
    max-width: 50%;
  }
  .main-box p{
    margin: 32px 0 42px;
    font-size: 16px;
  }
  .main-box h1{
    line-height: 66px;
    font-size: 56px;
    margin: 0;
    font-weight: bold;
    color: #000;
    /*margin-top: 64px;*/
  }
  .main-box{
    box-shadow: inset 0 0 50px rgb(0 0 0 / 5%);
    box-sizing: border-box;
    height: 500px;
    width: 100%;
    /*padding: 0 72px;*/
    margin: 0 auto;
    background-color: #eef2f6;
    clear: both;
    background-repeat: no-repeat;
    background-size: cover;
    flex-wrap: wrap;
    font-family: PingFang-SC-Regular, "PingFang SC", "Helvetica Neue", Helvetica, Arial, "Microsoft Yahei", "Hiragino Sans GB", "Heiti SC", "WenQuanYi Micro Hei", sans-serif;
  }
  @media screen and (max-width: 1023px){
    .int-flex-box-item.step-full-box .int-flex-box-span{
        padding: 16px 20px ;
        text-align: left;
    }
    .int-flex-box-item.step-full-box>div>div{
      width: 100%;
      flex-basis: 100%;
      box-sizing: border-box;
      flex-basis: 100%;
      max-width: 100%;
    }

    div.introduce{
      padding: 0 32px;
    }
    .int-flex-box-half {
        box-sizing: border-box;
        flex-basis: 100%;
        max-width: 100%;
        padding: 0;
        flex-grow: 0;
        flex-shrink: 0;
    }
    .info-item-box{
      padding: 12px 36px;
    }
    .list-item-box{
      padding: 12px 0;
    }
    .init-title-box{
      height: 150px;
    }
    .main-box p {
      margin: 24px 0;
    }
    .main-box {
      padding: 0 12px;
      height: unset;
    }
    .earth-row{
      height: unset;
    }
    .earth-row>div{
      width: 100%;
      max-width: 100%;
      flex-basis: 100%;
      padding: 80px 0 36px;
    }
    .earth-row-img{
      display: none;
    }
    .main-box h1{
      font-size: 36px;
      font-weight: bold;
      color: #000;
    }
    .int-flex-box-item{
      width: 100%;
      max-width: 100%;
      flex-basis: 100%;
    }
    .int-flex-box-item>div{
      flex-direction: column;
      align-items: center;
    }
    .int-flex-box-span {
      padding: 48px 26px 0;
    }
  }
</style>