<template>
  <div class="md-layout text-center">
    <!-- <notifications></notifications> -->
    <div
      class="
        md-layout-item
        md-size-66
        md-medium-size-66
        md-small-size-90
        md-xsmall-size-100
      "
    >
      <!--  header-color="blue" -->
      <login-card :typeName="type == 1 ?'邮箱' :'手机'"> 
        <!-- <p slot="description" class="description">Or Be Classical</p> -->
        <md-field v-if="type == 1" class="md-form-group" slot="inputs">
          <md-icon>email</md-icon>
          <label>邮箱</label>
          <md-input v-model="email"></md-input>
        </md-field>
        <md-field v-if="type == 2" class="md-form-group" slot="inputs">
          <md-icon>phone</md-icon>
          <label>手机号</label>
          <md-input v-model="phone"></md-input>
        </md-field>
        <md-field class="md-form-group" slot="inputs">
          <md-icon>lock_outline</md-icon>
          <label>密码</label>
          <md-input v-model="passwd" type="password"></md-input>
        </md-field>
        <md-button slot="footer" class="diy-submit md-info md-round" @click="submit">
          {{$I18n("login")}}
        </md-button>
        <md-button slot="footer" class="diy-submit md-info md-round" @click="changeType">
          {{type == 1 ?"切换至手机登录" :"切换至邮箱登录"}}
        </md-button>
      </login-card>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import { LoginCard } from "@/components";
export default {
  components: {
    LoginCard,
  },
  data() {
    return {
      axios : axios.create(),
      passwd: "",
      email : "",
      phone : "",
      type  : 2 , // 1: 邮箱; 2: 电话
    };
  },
  created(){
    
  },
  methods: {
    changeType(){
      if(this.type == 1){
        this.type = 2;
      } else if (this.type == 2){
        this.type = 1;
      }
    },

    successMsg(msg, time) {
      this.$emit("sucmsg", msg, time)
    },
    errorMsg: function(msg){
      this.$emit("errmsg", msg)
    },
    errorCodeMsg(code){
      console.log("CODE: ", code)
      if(code == 514){
        this.errorMsg("修改密码失败，验证码错误！")
      } else if (code == 406){
        this.errorMsg("登录失败，账号不存在")
      } else if (code == 512){
        this.errorMsg("密码错误")
      } else {
        this.errorMsg("登陆失败")
      }
    },
    submit_email(){
      if(!this.email){
        this.errorMsg("请输入邮箱")
        return
      }
      if(!this.passwd){
        this.errorMsg("请输入密码")
        return
      }
      return this.$api._apiSend({
        b: {
          "client_id"  : this.$api._client_id,
          "client_type": this.$api._client_type,
          "custom_flag": this.$api._custom_flag,
          "user_id"    : this.email,
        },
        h: {
          i :100,
          v :1,
        },
      })
      .then(res => {
        if(res.h.e == 200){
          const res_url = res.b.umh_ip;
          this.$api._apiSend({
            b: {
              "client_id"  : "web",
              "client_type": 6,
              "custom_flag": "aMTcxOTk4ODM1NDIxNzA2",
              "client_lang": 2,
              "enc_type"   : 2,
              "user_id"    : this.email,
              "user_passwd": this.passwd,
            },
            h: {
              i :202,
              v :1,
              sp: res_url,
            },
          })
            .then(result => {
              if(result.h.e == 200){
                this.successMsg("登录成功", 1000)
                setTimeout(() => {
                  localStorage.token  = result.b.token;
                  localStorage.umh_ip = res_url;
                  localStorage.login  = "email"
                  this.$router.push("/home")
                },1000)
              } else {
                this.errorCodeMsg(result.h.e)
              }
            })
            .catch(e => {
              console.error(e)
              this.errorMsg("登陆失败")
            })
        }else{
          this.errorCodeMsg(res.h.e)
        }
      })
      .catch(e => {
        console.error(e)
        this.errorMsg("登陆失败")
      })
    },
    submit_phone(){
      if(!this.phone){
        this.errorMsg("请输入手机号")
        return
      }
      if(!this.passwd){
        this.errorMsg("请输入密码")
        return
      }
      return this.$api._apiSend({
        b: {
          "client_id"  : this.$api._client_id,
          "client_type": this.$api._client_type,
          "custom_flag": this.$api._custom_flag,
          "user_id"    : "86"+this.phone,
        },
        h: {
          i :100,
          v :1,
        },
      })
      .then(res => {
        if(res.h.e == 200){
          const res_url = res.b.umh_ip;
          this.$api._apiSend({
            b: {
              "client_id"  : "web",
              "client_type": 6,
              "custom_flag": "aMTcxOTk4ODM1NDIxNzA2",
              "user_id"    : "86" + this.phone,
              "client_lang": 2,
              "enc_type"   : 2,
              "user_passwd": this.passwd,
            },
            h: {
              i :202,
              v :1,
              sp: res_url,
            },
          })
            .then(result => {
              if(result.h.e == 200){
                this.successMsg("登录成功", 1000)
                setTimeout(() => {
                  localStorage.token  = result.b.token;
                  localStorage.umh_ip = res_url;
                  localStorage.login  = "phone"
                  this.$router.push("/home")
                },1000)
              } else {
                this.errorCodeMsg(result.h.e)
              }
            })
            .catch(e => {
              console.error(e)
              this.errorMsg("登陆失败")
            })
        }else{
          this.errorCodeMsg(res.h.e)
        }
      })
      .catch(e => {
        console.error(e)
        this.errorMsg("登陆失败")
      })
    },
    submit (){
      if(this.type == 1){
        this.submit_email();
      } else if (this.type == 2){
        this.submit_phone();
      }
    },
  },
};
</script>

<style type="scss" scoped>
  .diy-submit{
    width: 90%;
  }
  .md-form-group{
    margin-top: 30px;
    margin-bottom: 20px;
  }
</style>
