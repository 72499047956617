<template>
    <div style="width: 100%;">
        <notifications></notifications>
        <md-card>
            <div class="form">
                <md-field>
                    <label>{{"设备"}}</label>
                    <md-select v-model="dev_id">
                        <md-option v-for="(i, index) in devList" :key="index" :value="i.dev_id">{{
                            i.node_name
                        }}</md-option>
                    </md-select>
                </md-field>
            </div>
            <!-- 卡片-主体 -->
            <div class="table">
                <md-card-content >
                    <md-table v-model="tableShowData" >
                        <md-table-row slot="md-table-row" slot-scope="{ item }">
                            <md-table-cell :md-label="'ID'">{{ item.id }}</md-table-cell>
                            <md-table-cell :md-label="'事件时间'">{{ item.start_time }}</md-table-cell>
                            <md-table-cell :md-label="'事件名称'">{{ item.type_name }}</md-table-cell>
                            <md-table-cell :md-label="'生命周期（天）'">{{ item.lifecycle }}</md-table-cell>
                            <md-table-cell :md-label="'图片'">
                                <img @click="() => show_img = item.thumb_url" :src="item.thumb_url" style="max-height: 50px;width: unset;" />
                            </md-table-cell>
                            <md-table-cell :md-label="'创建时间'">{{ item.create_time }}</md-table-cell>
                        </md-table-row>
                        <md-table-empty-state slot="md-table-empty-state">
                            {{$I18n("none_data")}}
                        </md-table-empty-state>
                    </md-table>
                      <md-card-actions v-show="count > 0" md-alignment="space-between">
                        <div class="total-page">
                          <p class="card-category">
                            {{$I18n("total" )}} : {{count}} 
                          </p>
                        </div>
                        <pagination :total="count" v-model="no" :per-page="size" class="pagination-no-border right-button" type= "info"/>
                      </md-card-actions>
                </md-card-content>
            </div>
        </md-card>
        <div class="phone_table">
            <md-card v-for="i in tableShowData" :key="i.id">
                <md-card-content >
                    <div style="display: flex;">
                        <div style="width: 50%;">
                            <div>{{i.start_time}}</div>
                            <div>{{i.type_name}}</div>
                        </div>
                        <div @click="() => show_img = i.thumb_url">
                            <img :src="i.thumb_url" style="width: 40vw;" />
                        </div>
                    </div>
                </md-card-content>
            </md-card>
        </div>
        <!-- 弹窗 -->
        <modal v-show="show_img" @close="show_img = ''">
            <!-- 弹窗-头部 -->
            <template slot="header">
                <md-button class="md-simple md-just-icon md-round modal-default-button" @click="show_img = ''">
                    <md-icon class="red-btn" >close</md-icon>
                </md-button>
            </template>
            <!-- 弹窗-主体 -->
            <template slot="body">
                <img :src="show_img" style="margin-top: 15px;" />
            </template>
        </modal>
    </div>
</template>

<script>
import { Modal } from '@/components';
import { nDaysAgo } from "@/common/tools.js";
import { Pagination } from "@/components";
export default {
    components: {Modal, Pagination},
    data(){
        return {
            show_img:"",
            dev_id: "",
            devList : [],
            tableShowData : [],
            no   :1,
            size :6,
            count:0,

            typeName: {
                1   : "设备故障报警,（设备上线，设备离线）",
                2   : "移动侦测报警",
                3   : "视频遮挡报警",
                4   : "视频丢失报警",
                5   : "探头报警(外部输入报警)",
                6   : "人体感应报警",
                7   : "门铃报警",
                8   : "硬盘故障报警",
                9   : "音频满报警",
                10  : "越线侦测报警",
                11  : "区域入侵报警",
                12  : "区域进入报警",
                13  : "区域离开报警",
                14  : "物品遗留报警",
                15  : "物品拾取报警",
                16  : "快速移动",
                17  : "人脸识别",
                18  : "敲门报警",
                19  : "徘徊侦测报警",
                20  : "停车侦测报警",
                21  : "人员聚集报警",
                22  : "黑名单报警",
                23  : "白名单报警",
                25  : "目标计数报警",
                26  : "温度报警",
                27  : "声音报警",
                28  : "VIP名单报警",
                29  : "陌生人报警",
                30  : "人员提醒",
                31  : "车辆提醒",
                32  : "访客呼叫，客户定制ID",
                33  : "人行检测",
                34  : "人形侦测报警",
                35  : "车牌检测报警",
                36  : "车形检测报警",
                37  : "低电量报警",
                38  : "UV光报警",
                39  : "白名单车牌报警",
                40  : "黑名单车牌报警",
                41  : "陌生车牌报警",
                42  : "电动车报警",
                44  : "雷达报警",
                45  : "雷达相机报警",
                46  : "电子围栏报警",
                47  : "巡更报警",
                48  : "可视对讲报警",
                49  : "未戴安全帽报警",
                50  : "火焰报警",
                51  : "电梯门未关闭",
                52  : "电梯超载",
                53  : "人员困梯",
                54  : "动火离人",
                55  : "温度预警报警",
                56  : "温度突变报警",
                57  : "异常火源报警",
                58  : "烟雾报警",
                59  : "未穿反光衣报警",
                60  : "垃圾溢出报警",
                73  : "绊线入侵报警",
            },
        }
    },
    computed:{
        dev() {
            let devJSON = this.devList.filter(i => i.dev_id == this.dev_id)
            if(devJSON && devJSON.length > 0){
                return devJSON[0].uid
            }
            return ""
        },
    },
    mounted : function() {
        this.api_getDev();
    },
    watch:{
        dev(){
            this.no = 1;
            this.api_getOss();
        },
        no(){
            this.api_getOss();
        },
    },
    methods: {
        successMsg(msg, time) {
          this.$emit("sucmsg", msg, time)
        },
        errorMsg: function(msg){
          this.$emit("errmsg", msg)
        },
        api_getDev(){
            return this.$api._apiSend({
                "b": {
                    "page_index": 0,
                    "page_size": 0,
                    "parent_node_id": "0",
                    "sub":2
                },
                "h": {
                    "c": 1,
                    "count": 0,
                    "e": 0,
                    "i": 231,
                    "m": 1,
                    "s": localStorage.token,
                    "v": 1
                },
                "r": {
                    app_id: this.$api._custom_flag,
                    client_id: this.$api._client_id,
                    client_type: this.$api._client_type,
                }
            })
                .then(res => {
                    const nodes = res.b.nodes;
                    nodes.forEach(i => console.log(i.uid))
                    this.devList = nodes;
                    if(nodes.length > 0){
                        this.dev_id = nodes[0].dev_id;
                    }
                })
        },
        api_getOss(){
            this.$api.getOssEvent({
                "start_time": nDaysAgo(7) + " 00:00:00" ,
                "end_time"  : nDaysAgo(0) + " 23:59:59",
                "uid"       : this.dev,
                "is_url" : 1,
                "is_user": 1,
                "channel": 0,
                "page_no": this.no,
                "page_size": this.size,
            })
                .then(res => {
                    this.successMsg("获取完成")
                    this.tableShowData = res.data.map(i => {
                        return {
                            type_name: this.typeName[i.type],
                            ...i,
                        }
                    });
                    this.count = res.count;
                    console.log(res);
                })
                .catch(e => {
                    console.error(e);
                    this.errorMsg("获取失败")
                })
        }
    },
}
</script>
<style lang="scss" scoped>
    .form{
        margin: 10px 20px 0; 
        width: 300px;
    }
    .hover-card{
        cursor: pointer;
    }
    .md-simple .red-btn{
        color: red !important;
    }
    .phone_table{
        display: none;
    }
    @media screen and (max-width : 900px) {
        .form{
            width: 200px;
        }
        .table{
            display:none;
        }
        .phone_table{
            display: block;
        }
    }
</style>