<template>
  <div> 
    <h4 class="modal-title">{{title}}</h4>
    <md-button class="md-simple md-just-icon md-round modal-default-button" @click="$emit('click')">
      <md-icon>clear</md-icon>
    </md-button>
  </div>
</template>
<script>
export default {
  name: "modal-head",
  // components:{ },
  props: {
    title:{
      type: String,
      default: "",
    },
  },
  // data(){ },
  // watch:{ },
  // methods:{ },
};
</script>