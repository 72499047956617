<template>
  <div class="md-layout">
    <label class="md-layout-item md-size-100 md-form-label" v-if="!!label">
      {{label}} 
      <i v-if="required" :style="{color: 'red'}">*</i>
      <template v-if="!!label.replace(/\s/,'')">:</template>
      <i :style="{opacity: 0}">:</i>
    </label>
    <div class="md-layout-item md-size-100 md-form-item">
      <slot></slot>
    </div>
  </div>
</template>
<script>
export default {
  name: "modal-form-item",
  props: {
    label : {
      type: String,
      default: "",
    },
    required : {
      type : Boolean,
      default : false,
    }
  },
};
</script>

<style lang="scss" scoped>
  .md-form-item {
    text-align: left;
  }
  .md-form-label{
    color: #aaa;
    font-weight: 400;
    margin-bottom: 0;
    font-size: 16px;
    line-height: 1.5;
    padding-top: 20px ;
    // padding-buttom: 20px ;
    padding-right: 15px !important;
    padding-left: 15px !important;
    text-align: left !important;
  }
</style>