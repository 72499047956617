<template>
  <md-card-header class="md-card-header-icon md-card-header-blue">
    <div class="card-icon">
      <md-icon>{{icon}}</md-icon>
    </div>
    <h4 class="title">{{title}}</h4>
    <slot/>
  </md-card-header>
</template>
<script>
export default {
  name: "card-head",
  props: {
    icon : {
      type: String,
      default: "",
    },
    title : {
      type: String,
      default: "",
    }
  },
};
</script>