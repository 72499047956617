<template>
  <div>
    <Menu></Menu>
    <div class="main-box ">
      <div class="earth-row">
        <div>
          <h1>关于我们</h1>
          <p>
            <!-- <span :style="{'font-size':'18px','font-weight':600}">咨询热线: 020-84210997</span> -->
            <br>
            <!-- <span :style="{'font-size':'18px','font-weight':600}"><i :style="{color:'#eef2f6'}">咨询热线: </i>020-85212728</span> -->
          </p>
          <div style="height: 48px; "></div>
        </div>
        <div class="earth-row-img">
          <img src="/img/img/index.png">
        </div>
      </div>
    </div>
    <div class="info-box">
      <div class="info-box-left">
        <img height="60" src="/img/img/umeyelogo1.png">
        <h4 class="left-title">科缔智能</h4> 
        <p>
         广州市科缔智能科技有限公司是针对安防行业成立的研发公司，经过多年的视频监控开发经验，现已已成为领先的安防产品及行业解决方案提供商，是一家专门从事互联网、流媒体、数字安防和智能家居控制等方面技术研究、产品开发、系统集成、服务提供的高科技企业。在嵌入式系统应用、数字视频处理、图像压缩算法、网络协议研究等方面都取得了耀眼的成绩，现面向全球提供领先的安防产品、专业的行业解决方案与优质的服务，为客户持续创造更大价值。
        </p>
        <!-- <p>
          公司拥有强大的技术研发团队，公司本科以上文凭占80%以上，其中博士生3人，研究生8人，本科生35人，公司的骨干力量从事安防行业长达10年之久，在整个行业中积累了很多宝贵的经验，他们是一支年轻有朝气、拥有与国内外各企业成功合作经验、富有创造力的团队。凭借专业团队的优势，技术创新能力，丰富的产品项目实施经验为客户提供最高品质的IT服务，及时、准确、完善的设计服务 客户认知其品牌以及产品的价值和潜力，在市场上获得成功。现公司经过几年深入研发,在手机视频监控应用上取得重大突破，可以实现流畅的手机视频数据传输。
        </p> -->
        <p>
          “您的安全，我们的使命与责任”，我们将秉承“以人为本，创造品牌，与时俱进，追求卓越”的方针，继续对市场进行入细致的观察，为客户创造更多价值，并为共同构建安全、便捷、稳定、轻松的高品质生活而不懈努力。
        </p>
        <!-- <p>网址:http://www.kdzn.top</p> -->
        <p>联系地址：广州市天河区天园街道金鹏大夏203</p>
        <!-- <p>联系人:</p> -->
        <p>许先生：13570322351</p>
        <!-- <p>咨询热线：020-85210997  020-85212728</p> -->
        <!-- <p>E-mail：support@kdzn.top</p> -->
        <!-- <p>广东华南师大科技创新有限公司（研发部）</p> -->
      </div>
      <div class="info-box-right">
        <h4>
          公司的研发团队成立于2008年，是一支年轻有朝气、拥有与国内外各企业成功合作经验、富有创造力的团队。凭借专业团队的优势，致力于基于3G网络化的视频监控和手机视频监控项目，丰富的产品项目实施经验和以客户为本的经营管理思想，与客户共创双赢。
        </h4>
        <h5>团队宗旨</h5>
        <p>力求为客户提供最高品质个性化的数字监控方案，因需而变，量身定制。并愿以专业的素养，兢业的态度，科学的流程，为客户提供及时、准确、完善的设计服务客户认知其品牌以及产品的价值和潜力，在市场上获得成功。</p>
        <p>* 我们追求原创，从不拘泥于模版式的解决方案，我们所做的每一个项目，都是为客户量身定做的精品。</p>
        <p>* 我们追求品质，从细节中体现客户产品的特质，从根本上提升企业品牌、促进销售，这是我们的精髓所在。</p>
        <p>* 我们用最尖端的技术打造尊贵，为您提供我们最优质的服务。</p>
        <h5>团队力量</h5>
        <p>个人的贡献促进团队发展，团队发展成就个人价值！团队的成员近30名，均毕业于专业211重点院校，具有高水平的研究和开发能力，不断推出更有竞争力的高品质的手机视频安防软件。我们提供的不仅是安防产品，我们提供的更是安全保障。</p>
        <h5>公司信誉</h5>
        <p>我们相信： 无数的成功代表以往的辉煌，不断的创新为我们赢得未来。 只有想不到，没有做不到；无线视频专家助您超越梦想。</p>
        <p>我们承诺： 以市场为导向，专注研发，用高品质的产品服务用户，一直是我们服务工作的指导方针和努力的目标。</p>
        <h5>团队服务</h5>
        <p>我们的服务项目包括产品：手机视频监控智能综合应用平台 ; 3G车载视频监控系统;视频监控网关 ; 手机客户端软件 ; 手机拍传 ; 手机流媒体综合平台对接</p>
      </div>
    </div>
    <Foot></Foot>
  </div>
</template>

<script>
import Menu from "@/pages/Dashboard/Menu.vue";
import Foot from "@/pages/Dashboard/Foot.vue";
export default {
  components:{
    Menu : Menu,
    Foot : Foot,
  },
  data() {
    return {
    };
  },
  mounted() {
  },
  methods : {
  }
};
</script>
<style scoped>
  body, h2, h3, h4, h5, h6, .h2, .h3, .h4, a, p{
    font-weight: 400 !important;
  }
  .info-box-right h5{
    font-size: 18px;
  }
  .info-box-right h4{
    display: block;
    padding: 20px;
    border-left: 6px rgb(153,153,153) solid;
    background-color: rgb(246,246,246);
    box-sizing: border-box;
    margin: 0;
    font-size: 14px;
    font-weight: 400;
  }
  .info-box{
    max-width: 1300px;

    margin-top: 100px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    margin-bottom: 104px;
    /*max-width: 1432px;*/
    /*padding: 0 80px;*/
    width: 100%;
    box-sizing: border-box;
    flex-direction: column;
  }
  .info-box .info-box-right{
    margin-top: 32px;
    flex: 1;
  }
  .info-box .info-box-left {
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 2px 8px 0 rgb(74 77 114 / 15%);
    margin-bottom: 24px;
    padding: 40px 24px 32px;
    box-sizing: border-box;
  }
  .info-box-left .left-title {
    color: #131415;
    font-family: PingFang SC;
    font-size: 18px;
    font-weight: 700;
    line-height: 28px;
  }
  @media (min-width: 768px){
    .info-box .info-box-right{
      margin-top: 40px;
    }
    .info-box-left .left-title {
      color: #131415;
      font-family: PingFang SC;
      font-size: 20px;
      font-weight: 700;
      line-height: 30px;
    }

    .info-box .info-box-left {
        margin-bottom: 40px;
        padding: 40px 32px;
    }
  }
  @media (min-width: 1024px){
    .info-box{
      flex-direction: row;
    }
    .info-box .info-box-right{
      margin-top: 0;
    }
    .info-box-left .left-title {
      color: #131415;
      font-family: PingFang SC;
      font-size: 20px;
      font-weight: 700;
      line-height: 30px;
    }
    .info-box .info-box-left {
        margin-right: 79px;
        width: 426px;
        padding: 36px 32px;
    }
  }

._id__menu-card__3O-sT {
}



  .int-flex-box-img img{
    width: 300px;
  }
  .int-flex-box-item-info p{
    margin: 4px 0;
  }
  .int-flex-box-item-info{
    font-size: 16px;
    font-family: "PingFang SC";
    line-height: 26px;
    color: rgb(96,98,102);
    margin: 0;
  }
  .int-flex-box-item-title{
    font-size: 20px;
    height: 30px;
    margin: 0;
    margin-bottom: 12px;
  }
  .int-flex-box-img{
    max-width: 300px;
    min-width: 300px;
  }
  .int-flex-box-span{
    display: flex;
    flex: 1 1 auto;
    padding: 54px 16px 54px 40px;
    flex-direction: column;
  }
  .int-flex-box-item>div:hover{
    top: -10px;
    box-shadow: 0 -2px 10px 0 hsl(225deg 3% 46% / 3%), 0 14px 20px 0 hsl(225deg 3% 46% / 18%);
  }
  .int-flex-box-item>div{
    display: flex;
    background-color: rgb(255, 255, 255);
    min-height: 240px;
    height: 100%;
    margin: 0px auto;
    box-shadow: 0 4px 15px 0 rgb(37 60 126 / 12%);
    border-radius: 8px;
    position: relative;
    transition: top .3s, box-shadow .3s;
    top: 0;
  }
  .int-flex-box-item.full-box{
    max-width: 100%;
    flex-basis: 100%;
  }
  .int-flex-box-item{
    padding: 12px;
    flex-basis: 50%;
    flex-grow: 0;
    flex-shrink: 0;
    max-width: 50%;
    box-sizing: border-box;
  }
  .int-flex-box{
    margin-left: -12px;
    margin-right: -12px;
    margin-top: -12px;
    margin-bottom: 12px;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    align-items: center;
  }
  .int-title-box{
    text-align: center;
    padding: 0 12px 60px;
  }
  .int-sub-title{
    margin-top:24px;
    color: rgb(96, 98, 102);
    font-size: 16px;
    line-height: 26px;
    height: 26px;
    margin-bottom: 0;
  }
  .int-title{
    line-height: 50px;
    height: 50px;
    margin: 0;
    font-size: 40px;
    font-weight: 700;
  }
  div.introduce {
    margin: 72px auto;
    /* margin: 72px 0; */
    padding: 0 72px;
    max-width: 1200px;
  }
  .earth-row{
    max-width: 1300px;
    display: flex;
    margin: 0 auto;
    height: 500px;
  }
  .earth-row img{
    width: 100%;
  }
  .earth-row p{
    color: #606266;
    line-height: 26px;
  }

  .earth-row>div{
    align-items:center;
    padding: 0 12px;
    width: 50%;
    align-self: center;
    box-sizing: border-box;
    /*padding-left: 12px;*/
    /*padding-right: 12px;*/
    flex-basis: 50%;
    flex-grow: 0;
    flex-shrink: 0;
    max-width: 50%;
  }
  .main-box p{
    margin: 32px 0 42px;
    font-size: 16px;
  }
  .main-box h1{
    line-height: 66px;
    font-size: 56px;
    margin: 0;
    font-weight: bold;
    color: #000;
    /*margin-top: 64px;*/
  }
  .main-box{
    box-shadow: inset 0 0 50px rgb(0 0 0 / 5%);
    box-sizing: border-box;
    height: 500px;
    width: 100%;
    /*padding: 0 72px;*/
    margin: 0 auto;
    background-color: #eef2f6;
    clear: both;
    background-repeat: no-repeat;
    background-size: cover;
    flex-wrap: wrap;
    font-family: PingFang-SC-Regular, "PingFang SC", "Helvetica Neue", Helvetica, Arial, "Microsoft Yahei", "Hiragino Sans GB", "Heiti SC", "WenQuanYi Micro Hei", sans-serif;
  }
  @media screen and (max-width: 1023px){
    .info-box-right{
      padding: 40px 24px 32px;
    }
    div.introduce{
      padding: 0 32px;
    }
    .init-title-box{
      height: 150px;
    }
    .main-box p {
      margin: 24px 0;
    }
    .main-box {
      padding: 0 12px;
      height: unset;
    }
    .earth-row{
      height: unset;
    }
    .earth-row>div{
      width: 100%;
      max-width: 100%;
      flex-basis: 100%;
      padding: 80px 0 36px;
    }
    .earth-row-img{
      display: none;
    }
    .main-box h1{
      font-size: 36px;
      font-weight: bold;
      color: #000;
    }
    .int-flex-box-item{
      width: 100%;
      max-width: 100%;
      flex-basis: 100%;
    }
    .int-flex-box-item>div{
      flex-direction: column;
      align-items: center;
    }
    .int-flex-box-span {
      padding: 48px 26px 0;
    }
  }
</style>